import styles from "./HeaderCatalogue.module.scss";
import React from "react";
import { useParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import filter from "assets/img/filter.svg";
import { getNoun } from "plugins/getNoun";

const HeaderCatalogue = ({
  url,
  productsCount,
  sortingProducts,
  mainImage,
  name,
  priceSorting,
  setIsModalOpen,
}) => {
  const { catalogue } = useParams();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const priceSortionList = [
    { name: "По возрастанию", url: "asc" },
    { name: "По убыванию", url: "desc" },
  ];

  return (
    <div className={styles.heading_block}>
      {url === "Поиск" ? (
        catalogue === "filter" ? (
          <h1 className={styles.heading}>
            {productsCount}{" "}
            {getNoun(productsCount, "товар", "товара", "товаров")}
          </h1>
        ) : (
          <div>
            {/* <Tabs
                      tabs={[
                        { name: "products", title: "Товары" },
                        { name: "services", title: "Услуги" },
                      ]}
                      currentTab={sortingProducts}
                      changeTab={(value) => {
                        setProducts([]);
                        setSortingProducts(value);
                      }}
                    /> */}
            <p>
              По запросу {name} найдено {productsCount}{" "}
              {getNoun(
                productsCount,
                sortingProducts === "products" ? "товар" : "услуга",
                sortingProducts === "products" ? "товара" : "услуги",
                sortingProducts === "products" ? "товаров" : "услуг"
              )}{" "}
              <b>Вы искали {name}?</b>
            </p>
          </div>
        )
      ) : (
        <h1 className={styles.heading}>
          {mainImage && <img src={mainImage} alt={url} />}
          <b>{url}</b> {productsCount}{" "}
          {!isMobile && getNoun(productsCount, "товар", "товара", "товаров")}
        </h1>
      )}
      <div
        id="image"
        className={styles.navImage}
        onClick={() => setIsModalOpen(true)}
      >
        <p>
          {priceSortionList.find((item) => item.url === priceSorting)?.name}
        </p>
        <img src={filter} alt="menu" />
        <span>Фильтры</span>
      </div>
    </div>
  );
};
export { HeaderCatalogue };
