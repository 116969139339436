import * as url from "../endpoints";
import * as ERROR from "constants/error";
import { instance, config } from "./createAxios";
import { CHANGE_COUNT_IN_BASKET } from "constants/actionType";
import { removeToken } from "../token";
import store from "../../redux/stores";

export const axiosBasket = {
  async getMyCart() {
    try {
      const response = await instance.get(
        url.GET_CART() + `?city=${localStorage.getItem("city") || ""}`,
        config
      );
      store.dispatch({
        type: CHANGE_COUNT_IN_BASKET,
        countInBasket: localStorage.getItem("token")
          ? response.data?.items?.reduce(
              (sum, product) => sum + product.count,
              0
            ) || 0
          : JSON.parse(localStorage.getItem("basket"))?.reduce(
              (sum, product) => sum + product.count,
              0
            ) || 0,
      });
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async addToCart(id) {
    try {
      const response = await instance.put(url.ADD_TO_CART(id), {}, config);
      return response;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async removeFromCart(id) {
    try {
      const response = await instance.delete(url.REMOVE_FROM_CART(id), config);
      return response;
    } catch (error) {
      console.error(error);
      removeToken(error);
    }
    return { message: ERROR.ERROR_SERVER };
  },
  async changeCartProductCount(id, count) {
    try {
      const response = await instance.put(
        url.CHANGE_CART_PRODUCT_COUNT(id),
        { count },
        config
      );
      return response;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async getCountInMyCart() {
    try {
      let response = null;
      if (localStorage.getItem("token")) {
        response = await instance.get(url.GET_COUNT_IN_CART(), config);
      }
      store.dispatch({
        type: CHANGE_COUNT_IN_BASKET,
        countInBasket: localStorage.getItem("token")
          ? response?.data?.count || 0
          : JSON.parse(localStorage.getItem("basket"))?.reduce(
              (sum, product) => sum + product.count,
              0
            ) || 0,
      });
      return response?.data || 0;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async getPaymentUrl(params) {
    try {
      const response = await instance.post(
        url.GET_PAYMENT_URL(),
        params,
        config
      );
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async getMyCartForUnauth(params) {
    try {
      const response = await instance.post(url.GET_CART_FOR_UNAUTH(), {
        ...params,
        city: localStorage.getItem("city") || "",
      });
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
  async getSum(params) {
    try {
      const response = await instance.get(url.GET_SUM + params, config);
      return response.data;
    } catch (error) {
      console.error(error);
      removeToken(error);
      return error;
    }
  },
};
