import { useEffect, useState } from "react";
import styles from "./ModalAuth.module.scss";
import { Input, Link, Button, Checkbox } from "components";
import { ERR_NETWORK } from "constants/status";
import eye_close from "assets/img/eye-close.svg";
import eye_open from "assets/img/eye-open.svg";

import store from "../../redux/stores";
import { CHANGE_IS_OPEN_AUTH_MODAL } from "constants/actionType";
import { Helmet } from "react-helmet";
import { axiosAuth } from "plugins/axios";
import { useSelector } from "react-redux";
import { USER_CONDITIONS, PRIVACY_POLICY } from "plugins/endpoints";

const ModalAuth = () => {
  const isOpen = useSelector((state) => state.isOpenAuthModal);
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [selected, setSelected] = useState(false);
  const [loginPage, setLoginPage] = useState(true);
  const [сonfirmation, setСonfirmation] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState({
    login: "",
    email: "",
    password: "",
    errorMessage: "",
  });

  const closeWindow = () => {
    setLogin("");
    setPassword("");
    setEmail("");
    setError({
      login: "",
      email: "",
      password: "",
      errorMessage: "",
    });
    setLoginPage(true);
    store.dispatch({
      type: CHANGE_IS_OPEN_AUTH_MODAL,
      isOpenAuthModal: false,
    });
  };

  const changePage = () => {
    setLoginPage(!loginPage);
    setLogin("");
    setPassword("");
    setEmail("");
    setError({
      login: "",
      email: "",
      password: "",
      errorMessage: "",
    });
  };

  const submitData = async (event) => {
    event.preventDefault();
    let valid = true;
    setError({
      login: "",
      email: "",
      password: "",
      errorMessage: "",
    });
    if (login === "") {
      valid = false;
      setError((prev) => {
        return {
          ...prev,
          login: "Введите логин",
        };
      });
    }
    if (!loginPage && !/.+@.+\..+/.test(email)) {
      valid = false;
      setError((prev) => {
        return {
          ...prev,
          email: "Неверный формат",
        };
      });
    }
    if (
      !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_]).{8,}$/.test(
        password
      )
    ) {
      valid = false;
      setError((prev) => {
        return {
          ...prev,
          password: "Введите пароль",
        };
      });
    }
    if (!loginPage && !selected) {
      return false;
    }
    if (valid) {
      let response = null;
      if (loginPage) {
        response = await axiosAuth.getLogin({ login, password });
      } else {
        response = await axiosAuth.getSignUp({ login, email, password });
      }
      if (response.status === 400) {
        if (response.data.Error === "Such user already exists") {
          setError((prev) => {
            return {
              ...prev,
              errorMessage: "Такой пользователь уже существует",
            };
          });
        } else if (response.data.Error === "There is no such user") {
          setError((prev) => {
            return {
              ...prev,
              errorMessage: "Нет такого пользователя",
            };
          });
        } else {
          setError((prev) => {
            return {
              ...prev,
              errorMessage: ERR_NETWORK.message,
            };
          });
        }
      } else {
        if (response.access) {
          localStorage.setItem("token", response.access);
          localStorage.setItem("refresh_token", response.refresh);
          localStorage.setItem("name", response.username || "Пользователь");
          await axiosAuth.addItemsFromUnauth();
          window.location.reload();
        } else {
          setСonfirmation(true);
        }
      }
    } else {
      setError((prev) => {
        return {
          ...prev,
          errorMessage: "Проверьте входные данные",
        };
      });
    }
  };

  useEffect(() => {
    setLoginPage(true);
  }, [isOpen]);

  return (
    <>
      <Helmet>
        {isOpen && loginPage && (
          <script src={`${window.location.origin}/yandexButton.js`} />
        )}
      </Helmet>
      {сonfirmation ? (
        <div className={styles.form}>
          <h1>
            Мы отправили вам письмо с подтверждением регистрации. Пожалуйста
            перейдите по ссылке в письме и подтвердите почту.
          </h1>
        </div>
      ) : (
        <div className={styles.form}>
          <p>{loginPage ? "Войдите или зарегистрируйтесь" : "Регистрация"}</p>
          <Input
            placeholder={loginPage ? "Логин или Почта" : "Логин"}
            value={login}
            errorMessage={error.login}
            marginBottom="20px"
            onChange={(ev) => setLogin(ev.target.value)}
          />
          {!loginPage && (
            <Input
              placeholder="Email"
              value={email}
              errorMessage={error.email}
              marginBottom="20px"
              onChange={(ev) => setEmail(ev.target.value)}
            />
          )}
          <div className={styles.password}>
            <Input
              type={showPassword ? "text" : "password"}
              placeholder="Пароль"
              value={password}
              requiredText="Требования к паролю: английский алфавит, длина не менее 8 символов, содержание заглавной и прописной букв, цифры и спец. символ из #?!@$%^&*-_"
              errorMessage={error.password}
              marginBottom="20px"
              prepend={
                <img
                  src={showPassword ? eye_open : eye_close}
                  width="32px"
                  alt="Поиск"
                  onClick={() => setShowPassword(!showPassword)}
                />
              }
              onChange={(ev) => setPassword(ev.target.value)}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  submitData(event);
                }
              }}
            />
          </div>
          {!loginPage && (
            <div style={{ display: "flex", marginBottom: "25px" }}>
              <Checkbox
                width="2vh"
                height="2vh"
                isSelected={selected}
                marginRight="1vw"
                styleBack={{ borderRadius: "0.5vh" }}
                change={(value) => setSelected(value)}
              />
              <div className={styles.link}>
                Я соглашаюсь с{" "}
                <Link
                  path={process.env.REACT_APP_BASE_URL + USER_CONDITIONS}
                  color="#55C83D"
                  target="_blank"
                >
                  Условиями использования сайта
                </Link>{" "}
                и предоставляю согласие на обработку персональных данных в
                соответствии с{" "}
                <Link
                  path={process.env.REACT_APP_BASE_URL + PRIVACY_POLICY}
                  color="#55C83D"
                  target="_blank"
                >
                  Политикой обработки персональных данных
                </Link>
              </div>
            </div>
          )}
          {error.errorMessage !== "" ? <span>{error.errorMessage}</span> : null}
          <Button
            text={loginPage ? "Войти" : "Подтвердить почту"}
            padding="1.6vh 2.5vh"
            marginBottom="20px"
            click={submitData}
          />
          {loginPage && (
            <>
              <Button
                text="Зарегистрироваться"
                padding="1.6vh 2.5vh"
                marginBottom="30px"
                light
                click={changePage}
              />
              <Link
                path="/forgot_password"
                className={styles.forgot_password}
                onClick={closeWindow}
              >
                Забыли пароль?
              </Link>
              <div className={styles["horizontal-line"]} />
              <div id="yandex_id" />
            </>
          )}
        </div>
      )}
    </>
  );
};

export { ModalAuth };
