import styles from "./Loader.module.scss";

const Loader = ({ bgColor = "#ffffff", count = 8, align = "stretch" }) => {
  return <div className={styles.loader}>Загрузка...</div>;
  return (
    <div className={styles.cont_loader} style={{ alignItems: align }}>
      <figure>
        {[...Array(count)].map((x, i) => (
          <span key={i} style={{ background: bgColor }} alt="load..." />
        ))}
      </figure>
    </div>
  );
};

export { Loader };
