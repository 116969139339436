import styles from "./ProductList.module.scss";
import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import { NewProduct } from "components";
import { axiosFilter } from "plugins/axios";
import { Service } from "pages/Services/Service";

const ProductList = ({
  category,
  typeOfMedicine,
  categoriesProducts,
  selectedSymptoms,
  name,
  sortingProducts,
  setProductsCount,
  priceSorting,
}) => {
  const { catalogue, tab: productsTab } = useParams();
  const [searchParams] = useSearchParams();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [products, setProducts] = useState([]);

  const [isLoadingProducts, setIsLoadingProducts] = useState(true);
  const [wasLastList, setWasLastList] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(0);

  const getProducts = async () => {
    setIsLoadingProducts(true);
    let result = [];
    if (catalogue === "filter") {
      result = await axiosFilter.getComplexFilter(
        {
          type_of_medicine: typeOfMedicine,
          symptom_groups: category,
          symptoms: selectedSymptoms.toString(),
          categories: categoriesProducts.split(",").filter(Boolean),
          order_by: priceSorting,
          catalog_type: productsTab,
        },
        "&page=" + currentPage
      );
    } else {
      switch (productsTab) {
        case "hits":
        case "new":
        case "promotions":
        case "search":
          result = await axiosFilter.getDiseasesList(
            "&name=" +
              name +
              "&typeOfItems=" +
              sortingProducts +
              "&catalog_type=" +
              productsTab +
              "&page=" +
              currentPage
          );
          break;
        default:
          result = await axiosFilter.getMedicineFilterProducts(
            "&type_of_medicine=" +
              productsTab +
              "&symptom_groups=" +
              category +
              "&symptoms=" +
              selectedSymptoms +
              "&page=" +
              currentPage
          );
      }
    }
    if (!result?.results?.length || result?.message) {
      setWasLastList(true);
    } else {
      setPreviousPage(currentPage);
      setProducts([...products, ...result.results]);
      setProductsCount(result.count);
    }
    setIsLoadingProducts(false);
  };

  const onScroll = (event) => {
    const scrollBottom =
      event.target.scrollTop + event.target.offsetHeight >=
      event.target.scrollHeight - 15;
    if (scrollBottom) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    if (!wasLastList && previousPage !== currentPage) {
      getProducts();
    }
  }, [currentPage, previousPage, wasLastList]);

  useEffect(() => {
    setProducts([]);
    setWasLastList(false);
    setCurrentPage(1);
    setPreviousPage(0);
  }, [category]);

  useEffect(() => {
    setProducts([]);
    setWasLastList(false);
    setCurrentPage(1);
    setPreviousPage(0);
  }, [selectedSymptoms]);

  useEffect(() => {
    setProducts([]);
    setWasLastList(false);
    setCurrentPage(1);
    setPreviousPage(0);
  }, [searchParams.get("name")]);

  useEffect(() => {
    setProducts([]);
    setWasLastList(false);
    setCurrentPage(1);
    setPreviousPage(0);
  }, [
    searchParams.get("categories"),
    searchParams.get("order_by"),
    searchParams.get("type_of_medicine"),
  ]);

  return (
    <>
      <div className={styles.products} onScroll={onScroll}>
        {products.map((item) =>
          sortingProducts === "products" ? (
            <NewProduct
              key={item.id}
              item={item}
              width="auto"
              height="auto"
              imgStyle={{ width: !isMobile && "10vw" }}
              isDefaultStyle
              slider
            />
          ) : (
            <Service key={item.id} element={item} maxHeight="50vh" />
          )
        )}
      </div>
      {isLoadingProducts && <p>Загрузка, подождите...</p>}
    </>
  );
};
export { ProductList };
