import React, { useState, useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import styles from "./CreateOrder.module.scss";
import russian_post from "assets/img/russian_post.svg";
import edit from "assets/img/edit.svg";
import pdp from "assets/img/pdp.svg";

import { axiosBasket, axiosUserProfile, axiosStatic } from "plugins/axios";
import { vh, vw, vhM, vwM } from "plugins/convertPXtoVHVW";
import { checkError } from "plugins/checkError";
import { useOutsideClick } from "hooks";
import { STREETS } from "constants/streetType";
import {
  Input,
  Link,
  ModalNoButton,
  ModalTwoButtons,
  Checkbox,
} from "components";

const CreateOrder = ({ products, orderPrice, onClose, clearProducts }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const [orderInfo, setOrderInfo] = useState({
    fio: "",
    phone: "",
    city: "",
    type_street: "Улица",
    street: "",
    house: "",
    vlad: "",
    apartment: "",
    office: "",
    structure: "",
    block: "",
    post_index: "",
    terminal: 0,
    delivery_type: "",
    delivery_service: "",
  });
  const [error, setError] = useState({
    fio: "",
    phone: "",
    city: "",
    type_street: "",
    street: "",
    house: "",
    vlad: "",
    apartment: "",
    office: "",
    structure: "",
    block: "",
    post_index: "",
    terminal: "",
    errorMessage: "",
  });

  const [typeOfDelivery, setTypeOfDelivery] = useState(false);

  const debounceTimeout = useRef(null);
  const [addressText, setAddressText] = useState("");
  const [addresses, setAddresses] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [isAddressSelected, setAddressSelected] = useState(false);

  const debounceCityTimeout = useRef(null);
  const [cityText, setCityText] = useState("");
  const [cities, setСities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchCity, setIsSearchCity] = useState(false);
  const [isCitySelected, setCitySelected] = useState(true);

  const [modalLink, setModalLink] = useState(false);
  const [payLink, setPayLink] = useState("");
  const [sum, setSum] = useState(-1);
  const [openCity, setOpenCity] = useState(false);
  const [openSelect, setOpenSelect] = useState(false);
  const refSelect = useOutsideClick(() => setOpenSelect(false));

  const getCitiesTerminals = async () => {
    if (cityText) {
      let resCities = await axiosStatic.getTerminals(cityText, addressText);
      setAddresses(resCities);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    if (isSearch) {
      getCitiesTerminals();
      setIsSearch(false);
    } else {
      debounceTimeout.current = setTimeout(() => getCitiesTerminals(), 1500);
    }

    return () => clearTimeout(debounceTimeout.current);
  }, [cityText, addressText, isSearch]);

  const getCities = async () => {
    let result = await axiosStatic.getCities(cityText);
    setСities(result || []);
    setIsLoading(false);
  };

  useEffect(() => {
    if (debounceCityTimeout.current) clearTimeout(debounceCityTimeout.current);

    if (isSearchCity) {
      getCities();
      setIsSearchCity(false);
    } else {
      debounceCityTimeout.current = setTimeout(() => getCities(), 1500);
    }

    return () => clearTimeout(debounceCityTimeout.current);
  }, [cityText, isSearchCity]);

  const getSum = async () => {
    let res = null;
    if (orderInfo.delivery_type === "terminal" && orderInfo.terminal !== 0) {
      res = await axiosBasket.getSum(
        "?delivery_type=" +
          orderInfo.delivery_type +
          "&city=" +
          orderInfo?.city.trim() +
          "&cart_ids=" +
          products
            .filter((product) => product.isSelected)
            .map((product) => product.id) +
          "&terminal=" +
          orderInfo.terminal
      );
      let { isError, messageError } = checkError(res);
      if (isError) {
        setSum(-1);
        return;
      }
      setSum(res);
    } else if (
      orderInfo.delivery_type === "courier" &&
      orderInfo?.city &&
      orderInfo?.post_index
    ) {
      res = await axiosBasket.getSum(
        "?delivery_type=" +
          orderInfo.delivery_type +
          "&city=" +
          orderInfo?.city.trim() +
          "&cart_ids=" +
          products
            .filter((product) => product.isSelected)
            .map((product) => product.id) +
          "&index=" +
          orderInfo?.post_index
      );
      let { isError, messageError } = checkError(res);
      if (isError) {
        setSum(-1);
        return;
      }
      setSum(res);
    } else if (
      orderInfo.delivery_service === "pochta" &&
      orderInfo?.post_index
    ) {
      res = await axiosBasket.getSum(
        "?delivery_service=" +
          orderInfo.delivery_service +
          "&cart_ids=" +
          products
            .filter((product) => product.isSelected)
            .map((product) => product.id) +
          "&index=" +
          orderInfo?.post_index
      );
      let { isError, messageError } = checkError(res);
      if (isError) {
        setSum(-1);
        return;
      }
      setSum(res);
    } else {
      setSum(-1);
    }
  };

  useEffect(() => {
    getSum();
  }, [
    orderInfo.delivery_type,
    orderInfo.post_index,
    orderInfo?.city,
    orderInfo?.terminal,
    orderInfo?.delivery_service,
  ]);

  const checkProperties = () => {
    let valid = true;
    let error_check = {
      fio: "",
      phone: "",
      city: "",
      type_street: "",
      street: "",
      house: "",
      vlad: "",
      apartment: "",
      office: "",
      structure: "",
      block: "",
      post_index: "",
      terminal: "",
      errorMessage: "",
    };
    let optional_fields = ["apartment", "vlad", "office", "structure", "block"];
    if (orderInfo.delivery_type === "terminal") {
      optional_fields.push(...["street", "house"]);
    }
    if (orderInfo.delivery_service === "pochta") {
      optional_fields.push("delivery_type");
    }
    for (let key in orderInfo) {
      if (
        (orderInfo[key] === null || orderInfo[key] === "") &&
        !optional_fields.includes(key)
      ) {
        valid = false;
        error_check[key] = "Введите корректные данные";
      }
    }
    setError(() => error_check);
    if (!/^[\d\+][\d\(\)\ -]{4,14}\d$/.test(orderInfo.phone)) {
      valid = false;
      setError((prev) => {
        return {
          ...prev,
          phone: "Неверный формат",
        };
      });
    }
    if (sum === -1) {
      valid = false;
    }
    if (orderInfo.delivery_type === "terminal" && orderInfo.terminal === 0) {
      valid = false;
      setError((prev) => {
        return {
          ...prev,
          terminal: "Выберите терминал",
        };
      });
    }
    return valid;
  };

  const makeOrder = async () => {
    if (checkProperties()) {
      let paymentInfo = {
        ...orderInfo,
        carts: products
          .filter((product) => product.isSelected)
          .map((product) => product.id),
        type_street: orderInfo.type_street.toLowerCase(),
        post_index: orderInfo.post_index.toString(),
        delivery_price: sum,
      };
      let resPayment = await axiosBasket.getPaymentUrl(paymentInfo);
      let { isError, messageError } = checkError(resPayment);
      if (isError) {
        if (resPayment.response.status === 400) {
          if (
            resPayment.response.data.Error ===
            "You choose courier-type delivery, but address data were not provided"
          ) {
            setError((prev) => {
              return {
                ...prev,
                city: "Заполните поле города правильно",
                street: "Заполните поле адреса правильно",
                type_street: "Заполните поле типа адреса правильно",
                house: "Заполните поле номера дома правильно",
                post_index: "Заполните поле индекса правильно",
                errorMessage: "Заполните поля правильно",
              };
            });
          } else if (resPayment.response.data.Error === "Incorrect address") {
            setError((prev) => {
              return {
                ...prev,
                city: "Заполните поле города правильно",
                street: "Заполните поле адреса правильно",
                type_street: "Заполните поле типа адреса правильно",
                house: "Заполните поле номера дома правильно",
                post_index: "Заполните поле индекса правильно",
                vlad: !!paymentInfo.vlad && "Заполните поле владение правильно",
                apartment:
                  !!paymentInfo.apartment &&
                  "Заполните поле квартиры правильно",
                office:
                  !!paymentInfo.office && "Заполните поле офиса правильно",
                structure:
                  !!paymentInfo.structure &&
                  "Заполните поле строения правильно",
                block: !!paymentInfo.block && "Заполните поле блока правильно",
                errorMessage: "Заполните поля правильно",
              };
            });
          }
        } else {
          setError((prev) => {
            return {
              ...prev,
              errorMessage: messageError,
            };
          });
        }
      } else {
        localStorage.removeItem("basket");
        clearProducts();
        onClose();
        const newWindow = window.open("", "_blank");
        if (newWindow) {
          newWindow.location.href = resPayment;
        } else {
          setModalLink(true);
          setPayLink(resPayment);
        }
      }
    } else {
      setError((prev) => {
        return {
          ...prev,
          errorMessage: "Заполните поля правильно",
        };
      });
    }
  };

  const loadData = async () => {
    let resUserInfo = await axiosUserProfile.getProfileDetails();
    setOrderInfo({
      ...orderInfo,
      fio: resUserInfo?.fio || "",
      phone: resUserInfo?.phone?.replaceAll(" ", "") || "",
      city: resUserInfo?.city || "",
      post_index: resUserInfo?.post_index || "",
    });
    setCityText(resUserInfo?.city);
    getCitiesTerminals();
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <ModalTwoButtons
      title={
        typeOfDelivery ? (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <img src={pdp} alt={pdp} />
            <h1 style={{ fontSize: "1.9vh", marginLeft: "1vh" }}>
              Транспортная компания DPD
            </h1>
          </div>
        ) : (
          "Оформление заказа"
        )
      }
      description={
        <>
          {typeOfDelivery ? (
            <div>
              <div>
                <label
                  className={styles.type_label}
                  style={{
                    backgroundColor:
                      orderInfo.delivery_type === "courier"
                        ? "#EFFAF0"
                        : "#F8FBF9",
                  }}
                >
                  <Checkbox
                    isSelected={orderInfo.delivery_type === "courier"}
                    width={isMobile ? vhM(14) : vh(14)}
                    height={isMobile ? vhM(14) : vh(14)}
                    styleBack={{
                      padding: `${vh(2)} ${vw(2)}`,
                      borderRadius: vh(2.69),
                    }}
                    change={() =>
                      setOrderInfo({ ...orderInfo, delivery_type: "courier" })
                    }
                  />
                  <span>Доставка курьером</span>
                </label>
                <label
                  className={styles.type_label}
                  style={{
                    backgroundColor:
                      orderInfo.delivery_type === "terminal"
                        ? "#EFFAF0"
                        : "#F8FBF9",
                  }}
                >
                  <Checkbox
                    isSelected={orderInfo.delivery_type === "terminal"}
                    width={isMobile ? vhM(14) : vh(14)}
                    height={isMobile ? vhM(14) : vh(14)}
                    styleBack={{
                      padding: `${vh(2)} ${vw(2)}`,
                      borderRadius: vh(2.69),
                    }}
                    change={() => {
                      setOrderInfo({ ...orderInfo, delivery_type: "terminal" });
                      setOpenCity(true);
                    }}
                  />
                  <span>Самовывоз из почтомата</span>
                </label>
              </div>
              {orderInfo.delivery_type === "terminal" ? (
                <div className={styles.search}>
                  <Input
                    white
                    errorMessage={error.terminal}
                    placeholder="Выберите почтомат"
                    value={addressText}
                    fontColor="#5F6B69"
                    height={vh(50)}
                    width="100%"
                    borderBottom="1px solid #5F6B6980"
                    inputStyles={{
                      fontSize: isMobile ? vh(14) : vh(16),
                      lineHeight: isMobile ? vhM(18) : vh(20),
                    }}
                    prepend={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={isMobile ? vhM(12) : vh(12)}
                        height={isMobile ? vhM(13) : vh(13)}
                        viewBox="0 0 12 13"
                        fill="none"
                        className={openCity ? styles.open : ""}
                        onClick={() => setOpenCity(!openCity)}
                      >
                        <path
                          d="M1 4.5C1 4.5 4.04201 7.00735 5.76697 8.32346C5.90441 8.42833 6.09483 8.42413 6.22983 8.31614C8.04139 6.86689 9.12532 5.99974 11 4.5"
                          stroke="#5F6B69"
                          strokeWidth="0.61828"
                          strokeLinecap="round"
                        />
                      </svg>
                    }
                    onChange={(ev) => {
                      setAddressSelected(false);
                      setAddressText(ev.target.value);
                      setIsLoading(true);
                    }}
                    onKeyPress={(ev) => {
                      if (ev.key === "Enter") setIsSearch(true);
                    }}
                  />
                  {isLoading ? (
                    <div className={styles.search__empty}>Поиск...</div>
                  ) : isAddressSelected || !openCity ? (
                    <></>
                  ) : addresses.length ? (
                    <ul className={styles.search__list}>
                      {addresses.map((address) => (
                        <li
                          key={address.id}
                          className={
                            address.address === addressText
                              ? styles.select
                              : null
                          }
                          onClick={() => {
                            setAddressSelected(true);
                            setAddressText(address.address);
                            setOrderInfo({
                              ...orderInfo,
                              terminal: address.id,
                            });
                          }}
                        >
                          <span>{address.address}</span>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <div className={styles.search__empty}>
                      Такого города не найдено
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          ) : (
            <div>
              <Input
                green
                placeholder="ФИО"
                value={orderInfo.fio}
                errorMessage={error.fio}
                marginBottom={isMobile ? vhM(18) : vh(18)}
                inputStyles={{
                  fontSize: isMobile ? vhM(14) : vh(14),
                  lineHeight: isMobile ? vhM(18) : vh(16),
                  padding: `0 ${isMobile ? vwM(16) : vw(16)}`,
                }}
                onChange={(ev) =>
                  setOrderInfo({ ...orderInfo, fio: ev.target.value })
                }
              />
              <Input
                green
                placeholder="Номер телефона"
                value={orderInfo.phone}
                errorMessage={error.phone}
                marginBottom={isMobile ? vhM(18) : vh(18)}
                inputStyles={{
                  fontSize: isMobile ? vhM(14) : vh(14),
                  lineHeight: isMobile ? vhM(18) : vh(16),
                  padding: `0 ${isMobile ? vwM(16) : vw(16)}`,
                }}
                onChange={(ev) =>
                  setOrderInfo({ ...orderInfo, phone: ev.target.value })
                }
              />
              <div className={styles.search}>
                <Input
                  green
                  placeholder="Город"
                  value={cityText}
                  height={vh(50)}
                  borderBottom={isCitySelected ? "none" : "1px solid #5F6B6980"}
                  errorMessage={error.city}
                  inputStyles={{
                    fontSize: isMobile ? vhM(14) : vh(14),
                    lineHeight: isMobile ? vhM(18) : vh(16),
                    padding: `0 ${isMobile ? vwM(16) : vw(16)}`,
                  }}
                  onChange={(ev) => {
                    setCitySelected(false);
                    setCityText(ev.target.value);
                    setIsLoading(true);
                  }}
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter") setIsSearchCity(true);
                  }}
                />
                {isLoading ? (
                  <div className={styles.search__empty}>Поиск...</div>
                ) : isCitySelected ? (
                  <></>
                ) : cities.length ? (
                  <ul className={styles.search__list}>
                    {cities.map((address) => (
                      <li
                        key={address.name}
                        className={
                          address.name === cityText ? styles.select : null
                        }
                        onClick={() => {
                          setCitySelected(true);
                          setCityText(address.name);
                          setOrderInfo({ ...orderInfo, city: address.name });
                        }}
                      >
                        <span>{address.name}</span>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div className={styles.search__empty}>
                    Такого города не найдено
                  </div>
                )}
              </div>
              <div
                ref={refSelect}
                className={styles.custom_select}
                style={{ marginTop: isMobile ? vhM(18) : vh(18) }}
              >
                <button
                  className={openSelect ? styles.open : null}
                  onClick={() => setOpenSelect(!openSelect)}
                >
                  <span>
                    {orderInfo.type_street === "Улица"
                      ? "Тип улицы"
                      : orderInfo.type_street || "Тип улицы"}
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={vh(12)}
                    height={vh(13)}
                    viewBox="0 0 12 13"
                    fill="none"
                  >
                    <path
                      d="M1 4.5C1 4.5 4.04201 7.00735 5.76697 8.32346C5.90441 8.42833 6.09483 8.42413 6.22983 8.31614C8.04139 6.86689 9.12532 5.99974 11 4.5"
                      stroke="#5F6B69"
                      strokeWidth="0.61828"
                      strokeLinecap="round"
                    />
                  </svg>
                </button>
                <ul className={`${openSelect ? styles.open : null}`}>
                  <div>
                    {STREETS.map((street) => (
                      <li
                        key={street}
                        onClick={() =>
                          setOrderInfo({
                            ...orderInfo,
                            type_street: street,
                          })
                        }
                      >
                        <span
                          className={
                            orderInfo.type_street === street
                              ? styles.select
                              : null
                          }
                        >
                          {street}
                        </span>
                      </li>
                    ))}
                  </div>
                </ul>
              </div>
              <Input
                green
                placeholder="Улица"
                value={orderInfo.street}
                errorMessage={error.street}
                marginBottom={isMobile ? vhM(18) : vh(18)}
                inputStyles={{
                  fontSize: isMobile ? vhM(14) : vh(14),
                  lineHeight: isMobile ? vhM(18) : vh(16),
                  padding: `0 ${isMobile ? vwM(16) : vw(16)}`,
                }}
                onChange={(ev) =>
                  setOrderInfo({ ...orderInfo, street: ev.target.value })
                }
              />
              <Input
                green
                placeholder="Дом"
                type="number"
                value={orderInfo.house}
                errorMessage={error.house}
                marginBottom={isMobile ? vhM(18) : vh(18)}
                inputStyles={{
                  fontSize: isMobile ? vhM(14) : vh(14),
                  lineHeight: isMobile ? vhM(18) : vh(16),
                  padding: `0 ${isMobile ? vwM(16) : vw(16)}`,
                }}
                onChange={(ev) =>
                  setOrderInfo({ ...orderInfo, house: ev.target.value })
                }
              />
              <Input
                green
                placeholder="Владение"
                type="number"
                value={orderInfo.vlad}
                errorMessage={error.vlad}
                marginBottom={isMobile ? vhM(18) : vh(18)}
                inputStyles={{
                  fontSize: isMobile ? vhM(14) : vh(14),
                  lineHeight: isMobile ? vhM(18) : vh(16),
                  padding: `0 ${isMobile ? vwM(16) : vw(16)}`,
                }}
                onChange={(ev) =>
                  setOrderInfo({ ...orderInfo, vlad: ev.target.value })
                }
              />
              <Input
                green
                placeholder="Корпус"
                value={orderInfo.block}
                errorMessage={error.block}
                marginBottom={isMobile ? vhM(18) : vh(18)}
                inputStyles={{
                  fontSize: isMobile ? vhM(14) : vh(14),
                  lineHeight: isMobile ? vhM(18) : vh(16),
                  padding: `0 ${isMobile ? vwM(16) : vw(16)}`,
                }}
                onChange={(ev) =>
                  setOrderInfo({ ...orderInfo, block: ev.target.value })
                }
              />
              <Input
                green
                placeholder="Строение"
                value={orderInfo.structure}
                errorMessage={error.structure}
                marginBottom={isMobile ? vhM(18) : vh(18)}
                inputStyles={{
                  fontSize: isMobile ? vhM(14) : vh(14),
                  lineHeight: isMobile ? vhM(18) : vh(16),
                  padding: `0 ${isMobile ? vwM(16) : vw(16)}`,
                }}
                onChange={(ev) =>
                  setOrderInfo({ ...orderInfo, structure: ev.target.value })
                }
              />
              <Input
                green
                placeholder="Квартира"
                value={orderInfo.apartment}
                errorMessage={error.apartment}
                marginBottom={isMobile ? vhM(18) : vh(18)}
                inputStyles={{
                  fontSize: isMobile ? vhM(14) : vh(14),
                  lineHeight: isMobile ? vhM(18) : vh(16),
                  padding: `0 ${isMobile ? vwM(16) : vw(16)}`,
                }}
                onChange={(ev) =>
                  setOrderInfo({ ...orderInfo, apartment: ev.target.value })
                }
              />
              <Input
                green
                placeholder="Офис"
                value={orderInfo.office}
                errorMessage={error.office}
                marginBottom={isMobile ? vhM(18) : vh(18)}
                inputStyles={{
                  fontSize: isMobile ? vhM(14) : vh(14),
                  lineHeight: isMobile ? vhM(18) : vh(16),
                  padding: `0 ${isMobile ? vwM(16) : vw(16)}`,
                }}
                onChange={(ev) =>
                  setOrderInfo({ ...orderInfo, office: ev.target.value })
                }
              />
              <Input
                green
                placeholder="Почтовый индекс"
                value={orderInfo.post_index}
                errorMessage={error.post_index}
                marginBottom={isMobile ? vhM(35) : vh(35)}
                inputStyles={{
                  fontSize: isMobile ? vhM(14) : vh(14),
                  lineHeight: isMobile ? vhM(18) : vh(16),
                  padding: `0 ${isMobile ? vwM(16) : vw(16)}`,
                }}
                onChange={(ev) =>
                  setOrderInfo({ ...orderInfo, post_index: ev.target.value })
                }
              />
              <div>
                {addressText && orderInfo.delivery_type === "terminal" ? (
                  <div className={styles.selected_delivery_buttons}>
                    <div>
                      <img src={pdp} alt={pdp} />
                      <h1>Транспортная компания DPD</h1>
                    </div>
                    <p>{`Самовывоз: ${addressText}`}</p>
                    <div
                      onClick={() =>
                        setOrderInfo({
                          ...orderInfo,
                          delivery_type: "",
                          delivery_service: "",
                        })
                      }
                      style={{ padding: "0", cursor: "pointer" }}
                    >
                      <img src={edit} alt={edit} />
                      <h1>Изменить способ доставки</h1>
                    </div>
                  </div>
                ) : orderInfo.delivery_type === "courier" ? (
                  <div className={styles.selected_delivery_buttons}>
                    <div>
                      <img src={pdp} alt={pdp} />
                      <h1>Транспортная компания DPD</h1>
                    </div>
                    <p>Доставка курьером</p>
                    <div
                      style={{ padding: "0", cursor: "pointer" }}
                      onClick={() =>
                        setOrderInfo({
                          ...orderInfo,
                          delivery_type: "",
                          delivery_service: "",
                        })
                      }
                    >
                      <img src={edit} alt={edit} />
                      <h1 style={{ color: "#20B900" }}>
                        Изменить способ доставки
                      </h1>
                    </div>
                  </div>
                ) : orderInfo.delivery_service === "pochta" ? (
                  <div className={styles.selected_delivery_buttons}>
                    <div>
                      <img src={russian_post} alt={russian_post} />
                      <h1>Почта России</h1>
                    </div>
                    <div
                      style={{ padding: "0", cursor: "pointer" }}
                      onClick={() =>
                        setOrderInfo({
                          ...orderInfo,
                          delivery_type: "",
                          delivery_service: "",
                        })
                      }
                    >
                      <img src={edit} alt={edit} />
                      <h1 style={{ color: "#20B900" }}>
                        Изменить способ доставки
                      </h1>
                    </div>
                  </div>
                ) : (
                  <div className={styles.delivery_buttons}>
                    <div
                      onClick={() =>
                        setOrderInfo({
                          ...orderInfo,
                          delivery_service: "pochta",
                        })
                      }
                    >
                      <img src={russian_post} alt={russian_post} />
                      <h1>Почта России</h1>
                    </div>
                    <div
                      className={
                        products.find(
                          (product) =>
                            product.isSelected && !product.is_dpd_allowed
                        )
                          ? styles.unactive_delivery
                          : null
                      }
                      onClick={() => {
                        if (
                          !products.find(
                            (product) =>
                              product.isSelected && !product.is_dpd_allowed
                          )
                        ) {
                          setTypeOfDelivery(true);
                          setOrderInfo({
                            ...orderInfo,
                            delivery_service: "dpd",
                          });
                        }
                      }}
                    >
                      <img src={pdp} alt={pdp} />
                      <h1>
                        Транспортная компания DPD{" "}
                        {products.find(
                          (product) =>
                            product.isSelected && !product.is_dpd_allowed
                        ) && "(Недоступно)"}
                      </h1>
                    </div>
                  </div>
                )}
              </div>
              {orderInfo.delivery_type === "terminal" ? (
                <div className={styles.search}>
                  {isAddressSelected && (
                    <div className={styles.order_total}>
                      {sum === -1 ? (
                        <div>
                          Подсчет... Проверьте, что правильно заполнены поля
                          Город и Индекс для расчета доставки
                        </div>
                      ) : (
                        <>
                          <div className={styles.order_total__price}>
                            Сумма заказа
                          </div>
                          <div className={styles.order_delivery__price}>
                            {(sum + orderPrice.priceOfGoods).toFixed(2)} ₽
                          </div>
                          <div className={styles.order_total__items}>
                            <span>Стоимость доставки</span>
                            <span>{sum} ₽</span>
                          </div>
                          <div className={styles.order_total__items}>
                            <span>Стоимость товаров</span>
                            <span>{orderPrice.priceOfGoods.toFixed(2)} ₽</span>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div className={styles.order_total}>
                  {sum === -1 ? (
                    <div>
                      Подсчет... Проверьте, что правильно заполнены поля Город и
                      Индекс для расчета доставки
                    </div>
                  ) : (
                    <>
                      <div className={styles.order_total__price}>
                        Сумма заказа
                      </div>
                      <div className={styles.order_delivery__price}>
                        {(sum + orderPrice.priceOfGoods).toFixed(2)} ₽
                      </div>
                      <div className={styles.order_total__items}>
                        <span>Стоимость доставки</span>
                        <span>{sum} ₽</span>
                      </div>
                      <div className={styles.order_total__items}>
                        <span>Стоимость товаров</span>
                        <span>{orderPrice.priceOfGoods.toFixed(2)} ₽</span>
                      </div>
                    </>
                  )}
                </div>
              )}
              {!!error.errorMessage && (
                <span style={{ color: "red" }}>{error.errorMessage}</span>
              )}
            </div>
          )}
          {modalLink && (
            <ModalNoButton
              title=""
              description={
                <div style={{ fontSize: vh(18) }}>
                  Пожалуйста, отключите блокировку всплывающих окон или кликните
                  по ссылке:{" "}
                  <Link path={payLink} color="blue">
                    Оплата
                  </Link>
                </div>
              }
              close={() => setModalLink(false)}
            />
          )}
        </>
      }
      buttonStyle={
        isMobile
          ? {
              width: "100%",
              padding: `${vhM(13.5)} ${vwM(27)}`,
              fontSize: vhM(16),
              lineHeight: vhM(21),
            }
          : {
              width: "100%",
              padding: `${vh(13.5)} ${vw(27)}`,
              fontSize: vh(16),
              lineHeight: vh(21),
            }
      }
      button={{
        title: typeOfDelivery ? "Сохранить" : "Перейти к оплате",
        action: typeOfDelivery ? () => setTypeOfDelivery(false) : makeOrder,
      }}
      width={isMobile ? vwM(370) : vw(476)}
      close={
        typeOfDelivery
          ? () => {
              if (!isAddressSelected) {
                setAddressText("");
              }
              setTypeOfDelivery(false);
            }
          : onClose
      }
      buttonClose={typeOfDelivery ? { title: "Назад" } : { title: "Закрыть" }}
    />
  );
};

export { CreateOrder };
