import styles from "./Logo.module.scss";
import new_logo from "assets/img/new_logo.png";
import empty_product from "assets/img/empty_product.png";

const Logo = ({ isSeller, ...rest }) => {
  return isSeller ? (
    <div className={styles.logo_seller} style={{ ...rest }}>
      <img src={empty_product} alt="Шалфей - партнерство" />
      Шалфей - партнерство
    </div>
  ) : (
    <div
      className={styles.logo}
      style={{ display: "flex", alignItems: "center", ...rest }}
    >
      <img src={new_logo} style={{ height: "4.1vh" }} alt="экосистема шалфей" />
      <div>
        экосистема <br />
        шалфей
      </div>
    </div>
  );
};

export { Logo };
