import React, { useState, useEffect } from "react";
import styles from "./Wiki.module.scss";
import { MainMenu, Button, Article, ModalOneButton, Loader } from "components";
import { getNoun } from "plugins/getNoun";
import { vh, vw, vhM, vwM } from "plugins/convertPXtoVHVW";
import store from "../../redux/stores";
import { CHANGE_IS_OPEN_AUTH_MODAL } from "constants/actionType";
import { axiosArticle, axiosHome } from "plugins/axios";

import { Helmet } from "react-helmet";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CREATE_ARTICLE } from "constants/url";
import { useOutsideClick } from "hooks";
import { useMediaQuery } from "react-responsive";

const FILTER_POPULAR = { type: "popular", text: "По популярности" };
const FILTER_NEW = { type: "new", text: "Сначала новые" };
const FILTER_OLD = { type: "old", text: "Сначала старые" };
const FILTER_MAP = [FILTER_POPULAR, FILTER_NEW, FILTER_OLD];

const Articles = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [searchParams] = useSearchParams();
  const [countArticles, setCountArticles] = useState(0);
  const [articles, setArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [showAddArticleModal, setShowAddArticleModal] = useState(false);
  const [wasLastList, setWasLastList] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [previousPage, setPreviousPage] = useState(0);
  const [nextPage, setNextPage] = useState(2);
  const [typesOfMedicine, setTypesOfMedicine] = useState([]);
  const [previousTypeOfMedicicne, setPreviousTypeOfMedicine] = useState("");
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(FILTER_POPULAR.type);
  const [selectFilter, setSelectFilter] = useState(FILTER_POPULAR.type);

  const refFilter = useOutsideClick(() => {
    setOpenFilter(false);
  });

  const onScroll = (event) => {
    const scrollBottom =
      event.target.scrollTop + event.target.offsetHeight >=
      event.target.scrollHeight - 10;
    if (scrollBottom && currentPage < nextPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [searchParams.get("types_of_medicine"), selectedFilter]);

  useEffect(() => {
    const getArticles = async () => {
      setIsLoading(currentPage === 1);
      let resTypesOfMedicine = typesOfMedicine;
      if (!typesOfMedicine.length) {
        resTypesOfMedicine = await axiosHome.getMainTypes();
        setTypesOfMedicine(resTypesOfMedicine || []);
      }
      let res = await axiosArticle.getArticles(
        previousTypeOfMedicicne === searchParams.get("types_of_medicine")
          ? currentPage
          : 1,
        `types_of_medicine=${
          resTypesOfMedicine.find(
            (typeOfMedicine) =>
              typeOfMedicine.name === searchParams.get("types_of_medicine")
          )?.id || ""
        }&sorting=${selectedFilter}`
      );

      if (
        !res?.results?.length &&
        previousTypeOfMedicicne === searchParams.get("types_of_medicine")
      ) {
        setWasLastList(true);
        setIsLoading(false);
        return;
      }
      if (localStorage.getItem("token")) {
        setArticles([
          ...(previousTypeOfMedicicne ===
            searchParams.get("types_of_medicine") && currentPage !== 1
            ? articles
            : []),
          ...res.results,
        ]);
      } else {
        let articlesFromStorage =
          JSON.parse(localStorage.getItem("favorite_articles")) || [];
        setArticles([
          ...(previousTypeOfMedicicne ===
            searchParams.get("types_of_medicine") && currentPage !== 1
            ? articles
            : []),
          ...res.results.map((e) =>
            articlesFromStorage.includes(e.id) ? { ...e, in_favorite: true } : e
          ),
        ]);
      }
      setCountArticles(res.count);
      if (previousTypeOfMedicicne === searchParams.get("types_of_medicine")) {
        setNextPage(!res.next ? currentPage : currentPage + 1);
        setPreviousPage(currentPage);
      } else {
        setCurrentPage(1);
        setPreviousPage(0);
        setNextPage(2);
        setPreviousTypeOfMedicine(searchParams.get("types_of_medicine"));
      }

      setIsLoading(false);
    };
    if (
      (!wasLastList && previousPage !== currentPage) ||
      previousTypeOfMedicicne !== searchParams.get("types_of_medicine")
    ) {
      getArticles();
    }
  }, [
    searchParams.get("types_of_medicine"),
    currentPage,
    previousPage,
    wasLastList,
    selectedFilter,
  ]);

  const addArticle = () => {
    if (localStorage.getItem("token")) {
      navigate(CREATE_ARTICLE.url);
    } else {
      setShowAddArticleModal(true);
    }
  };
  const openAuth = () => {
    setShowAddArticleModal(false);
    store.dispatch({
      type: CHANGE_IS_OPEN_AUTH_MODAL,
      isOpenAuthModal: true,
    });
  };

  const favorite = async (id) => {
    let article = articles.find((e) => e.id === id);
    if (!article) return;
    if (localStorage.getItem("token")) {
      await axiosArticle.favoriteArticle(article.id);
      setArticles(
        articles.map((e) =>
          e.id === article.id
            ? {
                ...e,
                in_favorite: !e.in_favorite,
                like_count: !e.in_favorite
                  ? e.like_count + 1
                  : e.like_count - 1,
              }
            : e
        )
      );
    } else {
      if (article.in_favorite) {
        let newFavorite = JSON.parse(
          localStorage.getItem("favorite_articles")
        ).filter((e) => e !== article.id);
        localStorage.setItem("favorite_articles", JSON.stringify(newFavorite));
      } else {
        let newFavorite = localStorage.getItem("favorite_articles")
          ? [
              article.id,
              ...JSON.parse(localStorage.getItem("favorite_articles")),
            ]
          : [article.id];
        localStorage.setItem("favorite_articles", JSON.stringify(newFavorite));
      }
      setArticles(
        articles.map((e) =>
          e.id === article.id ? { ...e, in_favorite: !e.in_favorite } : e
        )
      );
    }
  };
  const changeFilter = (newType) => {
    setSelectedFilter(newType);
    setCurrentPage(1);
    setPreviousPage(2);
    setWasLastList(false);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        height: "100vh",
        overflowY: "hidden",
      }}
    >
      <Helmet title="Шалфей.Вики">
        <meta name="description" content={"Shalfey : Шалфей.Вики"} />
        <meta name="keywords" content="Шалфей.Вики" />
        <link rel="canonical" href="/wiki/articles" />
      </Helmet>

      {isLoading ? (
        <Loader bgColor="#20B900" align="center" />
      ) : (
        <>
          {openFilter && <div className={styles.filter__shadow} />}
          <div
            ref={refFilter}
            className={`${styles.filter__cont} ${
              openFilter ? styles.filter__open : ""
            }`}
          >
            {isMobile && (
              <div
                className={styles.filter__close}
                onClick={() => {
                  setOpenFilter(false);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={isMobile ? vhM(17) : vw(26)}
                  height={isMobile ? vhM(17) : vw(26)}
                  viewBox="0 0 26 26"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.28442 19.8617C5.07183 20.0722 5.06964 20.4156 5.27953 20.6288C5.48942 20.842 5.83191 20.8441 6.0445 20.6337L12.5152 14.2275L18.9031 20.7151C19.113 20.9283 19.4554 20.9305 19.668 20.72C19.8806 20.5096 19.8828 20.1661 19.6729 19.953L13.2851 13.4654L19.7599 7.05522C19.9725 6.84475 19.9747 6.50132 19.7648 6.28816C19.5549 6.07499 19.2124 6.07281 18.9998 6.28328L12.525 12.6934L6.13334 6.20192C5.92345 5.98876 5.58096 5.98657 5.36837 6.19704C5.15578 6.40751 5.15358 6.75094 5.36347 6.9641L11.7552 13.4556L5.28442 19.8617Z"
                    fill="#5F6B69"
                  />
                </svg>
              </div>
            )}
            <div className={styles.filter__header}>Фильтры</div>
            <div className={styles.filter__body}>
              {FILTER_MAP.map((filter, index) => (
                <div
                  key={index}
                  className={`${styles.filter__value} ${
                    filter.type === selectFilter ? styles.filter__select : ""
                  }`}
                  onClick={() => {
                    setSelectFilter(filter.type);
                  }}
                >
                  {filter.type === selectFilter ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={vh(9)}
                      height={vh(9)}
                      viewBox="0 0 9 9"
                      fill="none"
                      style={{ marginRight: vw(10) }}
                    >
                      <path
                        d="M3.00321 8.5C2.86508 8.49999 2.73261 8.44631 2.63492 8.35075L0.639869 6.3985C0.547833 6.3019 0.497716 6.1741 0.50008 6.04205C0.502444 5.91 0.557105 5.784 0.652543 5.69061C0.747982 5.59722 0.876743 5.54373 1.01169 5.54142C1.14664 5.5391 1.27724 5.58814 1.37596 5.67821L2.93141 7.20028L7.54779 0.724094C7.58647 0.668149 7.63612 0.620284 7.69386 0.583282C7.75161 0.54628 7.81629 0.520879 7.88416 0.508555C7.95202 0.496231 8.02171 0.497231 8.08918 0.511496C8.15664 0.52576 8.22054 0.553005 8.27715 0.591647C8.33376 0.630289 8.38196 0.679557 8.41894 0.736587C8.45592 0.793617 8.48095 0.857272 8.49257 0.92385C8.50419 0.990428 8.50217 1.0586 8.48662 1.12441C8.47108 1.19022 8.44232 1.25234 8.40202 1.30718L3.43038 8.2819C3.38684 8.34302 3.33013 8.39404 3.26424 8.43138C3.19836 8.46871 3.1249 8.49145 3.04906 8.49799C3.03374 8.49941 3.01841 8.5 3.00321 8.5Z"
                        fill="#20B900"
                      />
                    </svg>
                  ) : (
                    <></>
                  )}
                  {filter.text}
                </div>
              ))}
            </div>
            <div className={styles.filter__actions}>
              <Button
                text="Готово"
                bgColor="#20B900"
                color="#ffffff"
                fontSize={vh(16)}
                lineHeight={vh(21)}
                fontWeight="600"
                padding={`${vh(15)} ${vw(24)}`}
                width="100%"
                marginBottom={vh(20)}
                click={() => {
                  changeFilter(selectFilter);
                  setOpenFilter(false);
                }}
              />
              <Button
                text="Очистить"
                bgColor="unset"
                color="#9CA5A3"
                fontSize={vh(16)}
                lineHeight={vh(21)}
                fontWeight="600"
                padding={`${vh(6)} ${vw(24)}`}
                width="100%"
                click={() => {
                  setSelectFilter(FILTER_POPULAR.type);
                  changeFilter(FILTER_POPULAR.type);
                  setOpenFilter(false);
                }}
              />
            </div>
          </div>
          {!isMobile && (
            <div className={styles.menu}>
              <MainMenu
                visibleNews={true}
                visibleServices={false}
                visibleForum={true}
                titleMedicines="Познай свой мир медицины"
                isArticles={true}
                typeOfMedicine={searchParams.get("types_of_medicine")}
              />
            </div>
          )}
          <div
            style={{ overflowY: "scroll" }}
            className={styles.articles}
            onScroll={(event) => onScroll(event)}
          >
            <div className={styles.header}>
              <div className={styles.header__title}>
                Шалфей.Вики
                <span>
                  {countArticles}
                  {isMobile
                    ? ""
                    : ` ${getNoun(
                        countArticles,
                        "статья",
                        "статьи",
                        "статей"
                      )}`}
                </span>
              </div>
              <div className={styles.header__actions}>
                {!isMobile && (
                  <Button
                    text={
                      <span
                        style={{ display: "inline-flex", alignItems: "center" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={vh(14)}
                          height={vh(14)}
                          viewBox="0 0 14 14"
                          fill="none"
                          style={{ marginRight: vw(10) }}
                        >
                          <path
                            d="M7 1V13"
                            stroke="#20B900"
                            strokeWidth="2"
                            strokeLinecap="round"
                          />
                          <path
                            d="M1 7H13"
                            stroke="#20B900"
                            strokeWidth="2"
                            strokeLinecap="round"
                          />
                        </svg>
                        Добавить статью
                      </span>
                    }
                    bgColor="#F2F7F4"
                    color="#20B900"
                    padding={`${vh(6.5)} ${vw(18)}`}
                    borderRadius={vh(10)}
                    fontSize={vh(16)}
                    lineHeight={vh(30)}
                    click={addArticle}
                  />
                )}
                <Button
                  text={
                    <span
                      style={{ display: "inline-flex", alignItems: "center" }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        style={{ marginRight: vw(10) }}
                      >
                        <path
                          d="M3.60938 3.5752H5.46562"
                          stroke="#5F6B69"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7.32197 5.43125C6.82966 5.43125 6.35752 5.23568 6.00941 4.88757C5.66129 4.53945 5.46572 4.06731 5.46572 3.575C5.46572 3.08269 5.66129 2.61055 6.00941 2.26243C6.35752 1.91432 6.82966 1.71875 7.32197 1.71875C7.56574 1.71875 7.80712 1.76676 8.03233 1.86005C8.25754 1.95333 8.46217 2.09006 8.63454 2.26243C8.80691 2.4348 8.94364 2.63943 9.03692 2.86464C9.13021 3.08985 9.17822 3.33123 9.17822 3.575C9.17822 3.81877 9.13021 4.06015 9.03692 4.28536C8.94364 4.51057 8.80691 4.7152 8.63454 4.88757C8.46217 5.05994 8.25754 5.19667 8.03233 5.28995C7.80712 5.38324 7.56574 5.43125 7.32197 5.43125Z"
                          fill="#5F6B69"
                          stroke="#5F6B69"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.17822 3.57527L18.4595 3.5752"
                          stroke="#5F6B69"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M18.4593 11H16.603"
                          stroke="#5F6B69"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M14.7469 12.8561C15.2392 12.8561 15.7113 12.6605 16.0594 12.3124C16.4076 11.9643 16.6031 11.4921 16.6031 10.9998C16.6031 10.5075 16.4076 10.0354 16.0594 9.68724C15.7113 9.33912 15.2392 9.14355 14.7469 9.14355C14.2546 9.14355 13.7824 9.33912 13.4343 9.68724C13.0862 10.0354 12.8906 10.5075 12.8906 10.9998C12.8906 11.4921 13.0862 11.9643 13.4343 12.3124C13.7824 12.6605 14.2546 12.8561 14.7469 12.8561Z"
                          fill="#5F6B69"
                          stroke="#5F6B69"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12.8906 11.0001L3.60938 11"
                          stroke="#5F6B69"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M3.60938 18.4248H5.46562"
                          stroke="#5F6B69"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7.32197 20.2809C6.82966 20.2809 6.35752 20.0853 6.00941 19.7372C5.66129 19.3891 5.46572 18.9169 5.46572 18.4246C5.46572 17.9323 5.66129 17.4602 6.00941 17.112C6.35752 16.7639 6.82966 16.5684 7.32197 16.5684C7.56574 16.5684 7.80712 16.6164 8.03233 16.7097C8.25754 16.8029 8.46217 16.9397 8.63454 17.112C8.80691 17.2844 8.94364 17.489 9.03692 17.7143C9.13021 17.9395 9.17822 18.1808 9.17822 18.4246C9.17822 18.6684 9.13021 18.9098 9.03692 19.135C8.94364 19.3602 8.80691 19.5648 8.63454 19.7372C8.46217 19.9095 8.25754 20.0463 8.03233 20.1396C7.80712 20.2328 7.56574 20.2809 7.32197 20.2809Z"
                          fill="#5F6B69"
                          stroke="#5F6B69"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.17822 18.4249L18.4595 18.4248"
                          stroke="#5F6B69"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      {isMobile ? "" : "Фильтры"}
                    </span>
                  }
                  bgColor="unset"
                  color="#5F6B69"
                  padding={
                    isMobile ? `${vhM(11)} ${vwM(10)}` : `${vh(7)} ${vw(10)}`
                  }
                  borderRadius={isMobile ? 0 : vh(10)}
                  fontSize={isMobile ? 0 : vh(16)}
                  lineHeight={isMobile ? 0 : vh(30)}
                  click={() => {
                    setOpenFilter(true);
                    setSelectFilter(selectedFilter);
                  }}
                />
              </div>
            </div>
            {isMobile && (
              <div className={styles.articles__actions}>
                <Button
                  text="Мои статьи"
                  width={"auto"}
                  bgColor="#20B900"
                  color="#ffffff"
                  padding={`${vhM(11.5)} ${vwM(18)}`}
                  borderRadius={vhM(10)}
                  fontSize={vhM(16)}
                  lineHeight={vhM(21)}
                  textWrap="nowrap"
                  marginRight={vwM(35)}
                  click={() => {
                    navigate("/wiki/my_articles");
                  }}
                />
                <Button
                  text={
                    <span
                      style={{ display: "inline-flex", alignItems: "center" }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={vhM(12)}
                        height={vhM(12)}
                        viewBox="0 0 14 14"
                        fill="none"
                        style={{ marginRight: vwM(10) }}
                      >
                        <path
                          d="M7 1V13"
                          stroke="#20B900"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                        <path
                          d="M1 7H13"
                          stroke="#20B900"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                      </svg>
                      Добавить статью
                    </span>
                  }
                  bgColor="#F2F7F4"
                  color="#20B900"
                  padding={`${vhM(6.5)} ${vwM(19)}`}
                  borderRadius={vhM(10)}
                  fontSize={vhM(16)}
                  lineHeight={vhM(30)}
                  click={addArticle}
                />
              </div>
            )}
            <div className={styles.body}>
              {articles.length ? (
                articles.map((e) => (
                  <Article
                    key={e.id}
                    article={e}
                    favorite={() => favorite(e.id)}
                  />
                ))
              ) : (
                <>Пусто</>
              )}
            </div>
          </div>

          {showAddArticleModal && (
            <ModalOneButton
              title="Необходима авторизация"
              description="Для того, чтобы добавить статью, пройдите авторизацию на сайте"
              buttonStyle={
                isMobile
                  ? {
                      width: "100%",
                      padding: `${vhM(13.5)} ${vwM(27)}`,
                      fontSize: vhM(16),
                      lineHeight: vhM(21),
                    }
                  : {
                      width: "100%",
                      padding: `${vh(13.5)} ${vw(27)}`,
                      fontSize: vh(16),
                      lineHeight: vh(21),
                    }
              }
              button={{
                title: "Вход",
                action: openAuth,
              }}
              close={() => setShowAddArticleModal(false)}
            />
          )}
        </>
      )}
    </div>
  );
};

export { Articles };
