import React, { useState } from "react";
import styles from "./Select.module.scss";
import { Checkbox, Link } from "components";
import { useOutsideClick } from "hooks";

const Select = ({
  placeholder = null,
  value = null,
  options = [],
  showAllOptions = false,
  checkbox = false,
  showValue = false,
  path = "#",
  onChange,
  zIndex,
  minWidth,
  selectWidth = "fit-content",
  ...rest
}) => {
  const [showOptionList, setShowOptionList] = useState(false);

  let checkboxList = checkbox && Array.isArray(value) ? value : [];

  const handleClick = (value) => {
    if (checkbox) {
      let updatedList = [...checkboxList];
      if (updatedList.includes(value)) {
        updatedList = updatedList.filter((item) => item !== value);
      } else {
        updatedList.push(value);
      }
      checkboxList = [...updatedList];
      onChange(checkboxList);
      return;
    }
    onChange(value);
    setShowOptionList(false);
  };
  const ref = useOutsideClick(() => setShowOptionList(false));

  return (
    <div
      ref={ref}
      className={`${styles["select"]} ${
        showOptionList ? styles["select--active"] : ""
      }`}
      style={{ zIndex, minWidth, width: selectWidth, ...rest }}
    >
      <div
        className={styles["select__box"]}
        width={selectWidth}
        onClick={() => {
          if (!!options.length) {
            setShowOptionList(!showOptionList);
          } else {
            onChange(path);
          }
        }}
      >
        <span className={styles["box__text"]}>
          {showValue ? (
            checkboxList.length === 0 ? (
              <p>{placeholder}</p>
            ) : (
              checkboxList
            )
          ) : checkbox ? (
            <p>{placeholder}</p>
          ) : (
            value || <p>{placeholder}</p>
          )}
        </span>
      </div>
      {showOptionList && (
        <ul
          className={styles["select__options"]}
          style={{ maxHeight: showAllOptions && "inherit" }}
        >
          {path === "#"
            ? options.map((option, i) => (
                <li
                  className={styles["option"]}
                  key={`option-${option.value}-${i}`}
                  data-value={option.value}
                  onClick={
                    !checkbox ? () => handleClick(option.value) : undefined
                  }
                >
                  {checkbox ? (
                    <Checkbox
                      text={option.text}
                      value={checkboxList.includes(option.value)}
                      onChange={() => handleClick(option.value)}
                    />
                  ) : (
                    option.text
                  )}
                </li>
              ))
            : options.map((option, i) => (
                <Link
                  path={path}
                  key={`option-${option.value}-${i}`}
                  textDecoration="none"
                  className={styles["option"]}
                  onClick={
                    !checkbox ? () => handleClick(option.value) : undefined
                  }
                >
                  {checkbox ? (
                    <Checkbox
                      text={option.text}
                      value={checkboxList.includes(option.value)}
                      onChange={() => handleClick(option.value)}
                    />
                  ) : (
                    option.text
                  )}
                </Link>
              ))}
        </ul>
      )}
    </div>
  );
};

export { Select };
