import styles from "./ProductInfo.module.scss";
import star from "assets/img/star_12.svg";
import zoom from "assets/img/zoom.svg";
import up from "assets/img/up.svg";
import down from "assets/img/down.svg";

import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Button, CounterItem, ModalImg, MobileModalImg } from "components";
import { axiosFavorite, axiosBasket } from "plugins/axios";
import { ToastContainer, toast } from "react-toastify";
import { checkError } from "plugins/checkError";
import { getNoun } from "plugins/getNoun";

const ProductInfo = ({ product, create_product }) => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [selectedImage, setSelectedImage] = useState(0);
  const basketItem = JSON.parse(localStorage.getItem("basket"))?.find(
    (el) => el.id === product.id
  );
  const [inBasket, setInBasket] = useState(
    localStorage.getItem("token")
      ? !!product.count_in_basket
      : !!basketItem?.count
  );
  const [count, setCount] = useState(0);

  const favoriteItem = JSON.parse(
    localStorage.getItem("favorite_products")
  )?.find((el) => el === product.id);
  const [inFavorite, setInFavorite] = useState(
    localStorage.getItem("token")
      ? product.in_favorite
      : favoriteItem !== undefined
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMobileOpen, setModalMobileOpen] = useState(false);

  const info = [
    {
      property: "indications",
      text: "Показания к применению",
    },
    { property: "symptoms", text: "Симптомы" },
    { property: "year_classification", text: "Рекомендации по возрасту" },
    { property: "description", text: "Описание" },
    { property: "application_methods", text: "Способы применения" },
    { property: "dosage", text: "Дозировка" },
    { property: "contraindications", text: "Противопоказания" },
    {
      property: "storage_conditions",
      text: "Условия хранения и срок годности",
    },
  ];
  const NAMES_ID = [
    ...info.map((item) => item.property),
    "composition",
    "size",
  ];

  const showCategory = (id) => {
    const categories = document.getElementById(id);
    const galochka = document.getElementById("galochka_" + id);
    if (categories.style.display === "block") {
      categories.style.display = "none";
      galochka.innerHTML = `<img src=${down} alt="Вниз" />`;
    } else {
      categories.style.display = "block";
      galochka.innerHTML = `<img src=${up} alt="Вверх" />`;
    }
  };

  const addToBasket = async () => {
    if (!inBasket) {
      if (localStorage.getItem("token")) {
        let res = await axiosBasket.addToCart(product.id);
        let { isError, messageError } = checkError(res);
        if (isError) {
          if (res?.response?.data?.Error === "Such product already in cart") {
            setCount(res?.response?.data?.count_in_basket || 1);
          } else {
            toast.info(messageError, {
              position: "bottom-center",
              autoClose: 5000,
              theme: "light",
            });
            setInBasket(false);
            return;
          }
        }
        setInBasket(true);
      } else {
        let checkProductInStorage = (
          JSON.parse(localStorage.getItem("basket")) || []
        ).some((item) => item.id === product.id);
        if (!checkProductInStorage) {
          let newBasket = [
            { id: product.id, count: 1 },
            ...(JSON.parse(localStorage.getItem("basket")) || []),
          ];
          localStorage.setItem("basket", JSON.stringify(newBasket));
        } else {
          setCount(changeProductCount.count || 1);
        }
        setInBasket(true);
      }
    } else {
      await deleteProductFromCart();
    }
    await axiosBasket.getCountInMyCart();
  };

  const changeProductCount = async (value) => {
    if (value > 0) {
      if (localStorage.getItem("token")) {
        let res = await axiosBasket.changeCartProductCount(product.id, value);
        let { isError, messageError } = checkError(res);
        if (isError) {
          if (res?.response?.data?.Error === "Such product not in cart") {
            setCount(1);
            setInBasket(false);
          }
        } else {
          setCount(value);
        }
      } else {
        let newBasket = localStorage.getItem("basket")
          ? JSON.parse(localStorage.getItem("basket")).map((el) =>
              el.id === product.id ? { ...el, count: value } : el
            )
          : [];
        localStorage.setItem("basket", JSON.stringify(newBasket));
        setCount(value);
      }
    } else {
      await deleteProductFromCart();
    }
    await axiosBasket.getCountInMyCart();
  };

  const deleteProductFromCart = async () => {
    if (localStorage.getItem("token")) {
      let res = await axiosBasket.removeFromCart(product.id);
      let { isError, messageError } = checkError(res);
      if (isError) {
        toast.info(messageError, {
          position: "bottom-center",
          autoClose: 5000,
          theme: "light",
        });
        setInBasket(true);
        return;
      }
    } else {
      let newBasket = localStorage.getItem("basket")
        ? JSON.parse(localStorage.getItem("basket")).filter(
            (e) => e.id !== product.id
          )
        : [];
      localStorage.setItem("basket", JSON.stringify(newBasket));
    }
    setCount(1);
    setInBasket(false);
  };

  const addOrRemoveFavorite = async () => {
    let resp = null;
    if (localStorage.getItem("token")) {
      if (inFavorite) {
        resp = await axiosFavorite.removeFromFavorite(product.id);
      } else {
        resp = await axiosFavorite.addToFavorite(product.id);
      }
      let { isError, messageError } = checkError(resp);
      if (
        isError &&
        resp?.response?.data?.Error !==
          "Such product already not in favorite" &&
        resp?.response?.data?.Error !== "Such product already in favorite"
      ) {
        toast.info(messageError, {
          position: "bottom-center",
          autoClose: 5000,
          theme: "light",
        });
        return;
      }
    } else {
      if (inFavorite) {
        let newFavorite = JSON.parse(
          localStorage.getItem("favorite_products")
        ).filter((el) => el !== product.id);
        localStorage.setItem("favorite_products", JSON.stringify(newFavorite));
      } else {
        let newFavorite = localStorage.getItem("favorite_products")
          ? [
              product.id,
              ...JSON.parse(localStorage.getItem("favorite_products")),
            ]
          : [product.id];
        localStorage.setItem("favorite_products", JSON.stringify(newFavorite));
      }
    }
    if (inFavorite) {
      setInFavorite(false);
    } else {
      setInFavorite(true);
    }
  };

  useEffect(() => {
    setInFavorite(
      localStorage.getItem("token")
        ? product.in_favorite
        : favoriteItem !== undefined
    );
  }, [favoriteItem, product]);

  useEffect(() => {
    setInBasket(
      localStorage.getItem("token")
        ? !!product.count_in_basket
        : !!basketItem?.count
    );
    setCount(
      localStorage.getItem("token")
        ? product.count_in_basket || 1
        : basketItem?.count || 1
    );
  }, [basketItem, product]);
  useEffect(() => {
    for (let i = 0; i < NAMES_ID.length; i++) {
      const categories = document.getElementById(NAMES_ID[i]);
      const galochka = document.getElementById("galochka_" + NAMES_ID[i]);
      categories.style.display = "none";
      galochka.innerHTML = `<img src=${down} alt="Вниз" />`;
    }
  }, [product]);

  return (
    <>
      <ToastContainer containerId={product.id} />
      <div className={styles.first_section}>
        {isMobile && (
          <>
            <h1 className={styles.name}>{product.name}</h1>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "1vh 0",
                }}
              >
                <img
                  src={star}
                  alt="Оценка"
                  style={{ height: "1.2vh", marginRight: "0.5vh" }}
                />
                <h1 className={styles.mark}>
                  {product?.product_mark?.toFixed(2)} ({product.mark_count}{" "}
                  {getNoun(product.mark_count, "оценка", "оценки", "оценок")})
                </h1>
              </div>
              <h1 className={styles.vendor_code}>
                Артикул: {product.vendor_code}
              </h1>
            </div>
          </>
        )}
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div className={styles.all_pic}>
            {product.pic_urls?.length !== 0
              ? product.pic_urls?.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      border:
                        selectedImage === index ? "1px solid #20b900" : null,
                      borderRadius:
                        selectedImage === index
                          ? isMobile
                            ? "10px"
                            : "18.9952px"
                          : null,
                    }}
                    onClick={() => {
                      setSelectedImage(index);
                    }}
                  >
                    <img
                      src={
                        create_product
                          ? URL.createObjectURL(item.pic_url)
                          : item.pic_url
                      }
                      alt={item?.alt || `Товар ${index}`}
                    />
                  </div>
                ))
              : null}
          </div>
          <div className={styles.main_pic}>
            {product.pic_urls
              ? product.pic_urls?.length !== 0 && (
                  <img
                    src={
                      create_product
                        ? URL.createObjectURL(
                            product?.pic_urls[selectedImage]?.pic_url
                          )
                        : product?.pic_urls[selectedImage]?.pic_url
                    }
                    className={styles.main_img}
                    alt={
                      product?.pic_urls[selectedImage]?.alt ||
                      `Товар ${selectedImage}`
                    }
                    onClick={() => {
                      if (isMobile) setModalMobileOpen(true);
                    }}
                  />
                )
              : null}
            <img
              src={zoom}
              className={styles.zoom}
              alt="Увеличить"
              onClick={() => setModalOpen(!modalOpen)}
            />
          </div>
        </div>
        {modalOpen ? (
          <ModalImg
            isOpen={modalOpen}
            pic_urls={product.pic_urls}
            main_img={selectedImage}
            create={create_product}
            onClose={() => setModalOpen(false)}
          />
        ) : null}
        <MobileModalImg
          isOpen={modalMobileOpen}
          pic_urls={product.pic_urls}
          onClose={() => setModalMobileOpen(false)}
        />
        <div className={styles.main_info}>
          {!isMobile && (
            <>
              <h1 className={styles.vendor_code}>
                Артикул: {product.vendor_code}
              </h1>
              <h1 className={styles.name}>{product.name}</h1>
              {!create_product && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "0.7vh",
                  }}
                >
                  <img
                    src={star}
                    alt="Оценка"
                    style={{ height: "1.2vh", marginRight: "0.5vh" }}
                  />
                  <h1 className={styles.mark}>
                    {product?.product_mark?.toFixed(2)} ({product.mark_count}{" "}
                    {getNoun(product.mark_count, "оценка", "оценки", "оценок")})
                  </h1>
                </div>
              )}{" "}
            </>
          )}
          {product?.seller && (
            <div className={styles.weight}>
              <h1>Продавец: </h1>
              <h2 style={{ color: "#20B900", fontWeight: "700" }}>
                {product?.seller}
              </h2>
            </div>
          )}
          {product?.type_of_medicine?.name && (
            <div className={styles.weight}>
              <h1>Мир медицины: </h1>
              <h2>{product?.type_of_medicine?.name}</h2>
            </div>
          )}
          {!isMobile && (
            <div className={styles.weight}>
              <h1>Форма выпуска: </h1>
              <h2>{product.medicine_format}</h2>
            </div>
          )}
          <div className={styles.weight}>
            <h1>Бренд: </h1>
            <h2>{product.brand}</h2>
          </div>
          {isMobile && (
            <>
              <h1 className={styles.price}>{product.price}₽</h1>
              {inBasket ? (
                <CounterItem
                  count={count}
                  change={changeProductCount}
                  marginBottom={isMobile ? "1vh" : "auto"}
                  justifyContent="center"
                />
              ) : (
                <Button
                  text={"Добавить в корзину"}
                  padding={isMobile ? "1.5vh 0" : "8px 14px"}
                  marginBottom="20px"
                  marginTop={isMobile ? "1vh" : "auto"}
                  width={isMobile ? "100%" : "auto"}
                  click={addToBasket}
                />
              )}
              <div className={styles.like} onClick={addOrRemoveFavorite}>
                <div
                  title={
                    inFavorite
                      ? "Удалить из Избранного"
                      : "Добавить в Избранное"
                  }
                  className={`${styles.heart} ${
                    inFavorite ? styles.heart_active : styles.heart_not_active
                  }`}
                >
                  <svg
                    viewBox="0 0 26 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask id="path-1-inside-1_938_2" fill="white">
                      <path d="M12.4297 22.4384C5.13633 16.085 0 12.7195 0 7.36645C0 3.31843 2.89883 0 6.8683 0C9.6229 0 11.6669 1.68415 12.9286 4.07326C14.1902 1.68431 16.2341 0 18.9888 0C22.9588 0 25.8571 3.31909 25.8571 7.36645C25.8571 12.7189 20.7275 16.0791 13.4274 22.4384C13.1417 22.6872 12.7154 22.6872 12.4297 22.4384Z" />
                    </mask>
                    <path
                      d="M12.4297 22.4384C5.13633 16.085 0 12.7195 0 7.36645C0 3.31843 2.89883 0 6.8683 0C9.6229 0 11.6669 1.68415 12.9286 4.07326C14.1902 1.68431 16.2341 0 18.9888 0C22.9588 0 25.8571 3.31909 25.8571 7.36645C25.8571 12.7189 20.7275 16.0791 13.4274 22.4384C13.1417 22.6872 12.7154 22.6872 12.4297 22.4384Z"
                      fill="white"
                      stroke="#C1C1C1"
                      strokeWidth="1.88"
                      mask="url(#path-1-inside-1_938_2)"
                    />
                  </svg>
                </div>
                <p>В&nbsp;{inFavorite ? "избранном" : "избранное"}</p>
              </div>
            </>
          )}
          <div className={styles.weight}>
            <h1>Страна: </h1>
            <h2>{product.manufacturer_country}</h2>
          </div>
          {isMobile && (
            <div className={styles.weight}>
              <h1>Форма выпуска: </h1>
              <h2>{product.medicine_format}</h2>
            </div>
          )}
          <div
            className={styles.paragraph}
            onClick={() => showCategory("composition")}
          >
            <h2 className={styles.composition}>Состав</h2>
            <b id={`galochka_composition`}>
              <img src={down} alt="Вверх" />
            </b>
          </div>
          <div className={styles["horizontal-line"]} />
          <div id="composition" className={styles.text}>
            {product.composition}
          </div>
          <div
            className={styles.paragraph}
            onClick={() => showCategory("size")}
          >
            <h2 className={styles.composition}>Габариты</h2>
            <b id={`galochka_size`}>
              <img src={down} alt="Вверх" />
            </b>
          </div>
          <div className={styles["horizontal-line"]} />
          <div id="size" className={styles.text}>
            <div className={styles.weight}>
              <h1>Вес/Объём: </h1>
              <h2>{product.weight}</h2>
            </div>
            <div className={styles.weight}>
              <h1>Длина: </h1>
              <h2>{product.length}</h2>
            </div>
            <div className={styles.weight}>
              <h1>Ширина: </h1>
              <h2>{product.width}</h2>
            </div>
            <div className={styles.weight}>
              <h1>Высота: </h1>
              <h2>{product.height}</h2>
            </div>
            <div className={styles.weight}>
              <h1>Количество в упаковке: </h1>
              <h2>{product.box_count}</h2>
            </div>
          </div>
          {!isMobile && (
            <>
              <h1 className={styles.price}>{product.price}₽</h1>
              {!create_product ? (
                inBasket ? (
                  <CounterItem
                    count={count}
                    change={changeProductCount}
                    marginBottom={isMobile ? "1vh" : "auto"}
                    justifyContent="center"
                  />
                ) : (
                  <Button
                    text={"Добавить в корзину"}
                    padding={isMobile ? "1.5vh 0" : "8px 14px"}
                    marginBottom="20px"
                    marginTop={isMobile ? "1vh" : "auto"}
                    width={isMobile ? "100%" : "auto"}
                    click={addToBasket}
                  />
                )
              ) : (
                <Button
                  text={"Добавить в корзину"}
                  padding="8px 14px"
                  marginBottom="20px"
                />
              )}
            </>
          )}
        </div>
        {!isMobile && (
          <>
            {!create_product ? (
              <div className={styles.like} onClick={addOrRemoveFavorite}>
                <div
                  title={
                    inFavorite
                      ? "Удалить из Избранного"
                      : "Добавить в Избранное"
                  }
                  className={`${styles.heart} ${
                    inFavorite ? styles.heart_active : styles.heart_not_active
                  }`}
                >
                  <svg
                    viewBox="0 0 26 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask id="path-1-inside-1_938_2" fill="white">
                      <path d="M12.4297 22.4384C5.13633 16.085 0 12.7195 0 7.36645C0 3.31843 2.89883 0 6.8683 0C9.6229 0 11.6669 1.68415 12.9286 4.07326C14.1902 1.68431 16.2341 0 18.9888 0C22.9588 0 25.8571 3.31909 25.8571 7.36645C25.8571 12.7189 20.7275 16.0791 13.4274 22.4384C13.1417 22.6872 12.7154 22.6872 12.4297 22.4384Z" />
                    </mask>
                    <path
                      d="M12.4297 22.4384C5.13633 16.085 0 12.7195 0 7.36645C0 3.31843 2.89883 0 6.8683 0C9.6229 0 11.6669 1.68415 12.9286 4.07326C14.1902 1.68431 16.2341 0 18.9888 0C22.9588 0 25.8571 3.31909 25.8571 7.36645C25.8571 12.7189 20.7275 16.0791 13.4274 22.4384C13.1417 22.6872 12.7154 22.6872 12.4297 22.4384Z"
                      fill="white"
                      stroke="#C1C1C1"
                      strokeWidth="1.88"
                      mask="url(#path-1-inside-1_938_2)"
                    />
                  </svg>
                </div>
                <p>В&nbsp;{inFavorite ? "избранном" : "избранное"}</p>
              </div>
            ) : (
              <div className={styles.like}>
                <div
                  title={"Добавить в Избранное"}
                  className={`${styles.heart} ${styles.heart_not_active}`}
                >
                  <svg
                    viewBox="0 0 26 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask id="path-1-inside-1_938_2" fill="white">
                      <path d="M12.4297 22.4384C5.13633 16.085 0 12.7195 0 7.36645C0 3.31843 2.89883 0 6.8683 0C9.6229 0 11.6669 1.68415 12.9286 4.07326C14.1902 1.68431 16.2341 0 18.9888 0C22.9588 0 25.8571 3.31909 25.8571 7.36645C25.8571 12.7189 20.7275 16.0791 13.4274 22.4384C13.1417 22.6872 12.7154 22.6872 12.4297 22.4384Z" />
                    </mask>
                    <path
                      d="M12.4297 22.4384C5.13633 16.085 0 12.7195 0 7.36645C0 3.31843 2.89883 0 6.8683 0C9.6229 0 11.6669 1.68415 12.9286 4.07326C14.1902 1.68431 16.2341 0 18.9888 0C22.9588 0 25.8571 3.31909 25.8571 7.36645C25.8571 12.7189 20.7275 16.0791 13.4274 22.4384C13.1417 22.6872 12.7154 22.6872 12.4297 22.4384Z"
                      fill="white"
                      stroke="#C1C1C1"
                      strokeWidth="1.88"
                      mask="url(#path-1-inside-1_938_2)"
                    />
                  </svg>
                </div>
                <p>В избранное</p>
              </div>
            )}
          </>
        )}
      </div>
      <div className={styles.info}>
        {info.map((item, i) => (
          <div key={i}>
            <div
              className={styles.paragraph}
              onClick={() => showCategory(item.property)}
            >
              <h2>{item.text}</h2>
              <b id={`galochka_${item.property}`}>
                <img src={down} alt="Вверх" />
              </b>
            </div>
            <div className={styles["horizontal-line"]} />
            <div id={item.property} className={styles.text}>
              {typeof product[item.property] === "object"
                ? product[item.property]?.map((id, i) => {
                    return create_product ? (
                      <div key={i} className={styles.object}>
                        {id}
                      </div>
                    ) : (
                      <div key={i} className={styles.object}>
                        {id.name || id.base_name}
                      </div>
                    );
                  })
                : product[item.property]}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export { ProductInfo };
