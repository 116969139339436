import styles from "./Catalogue.module.scss";
import {
  Loader,
  MainMenu,
  ProductList,
  Filter,
  ModalWindow,
  MobileFilter,
  HeaderCatalogue,
} from "components";

import Helmet from "react-helmet";
import { useParams, useSearchParams, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { axiosFilter } from "plugins/axios";
import { useMediaQuery } from "react-responsive";

import EmptyProduct from "assets/img/empty_product.png";
import { useOutsideClick } from "hooks";

const Catalogue = () => {
  const { catalogue, tab: productsTab } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState("");

  const [priceSorting, setPriceSorting] = useState("");
  const [typeOfMedicine, setTypeOfMedicine] = useState([]);
  const [categoriesProducts, setCategoriesProducts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterWidth, setFilterWidth] = useState("30vw");

  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("");
  const [mainImage, setMainImage] = useState(null);
  const [symptoms, setSymptoms] = useState([]);
  const [selectedSymptoms, setSelectedSymptoms] = useState([]);

  const [productsCount, setProductsCount] = useState(0);
  const [name, setName] = useState(searchParams.get("name") || "");
  const [sortingProducts, setSortingProducts] = useState("products");

  const getDiseases = async () => {
    setLoading(true);
    let cat_temp = Number(searchParams.get("parent_category")) || "";
    setCategory(cat_temp);
    switch (productsTab) {
      case "hits":
        setUrl("Хиты");
        break;
      case "new":
        setUrl("Новинки");
        break;
      case "promotions":
        setUrl("Акции");
        break;
      case "search":
        setUrl("Поиск");
        break;
      default:
        let result = await axiosFilter.getMedicineFilter(
          "type_of_medicine=" + productsTab
        );
        if (result?.message) {
          navigate("*");
        }
        setMainImage(result.medicine_icon);
        setUrl(productsTab);
        if (cat_temp) {
          let selectedCategory = result.symptoms.find(
            (item) => item.id === cat_temp
          );
          if (selectedCategory) {
            let newCategories = result.symptoms.filter(
              (item) => item.id !== selectedCategory?.id
            );
            setCategories([selectedCategory, ...newCategories]);
          }
          let resultSymptoms = await axiosFilter.getMedicineFilter(
            "type_of_medicine=" + productsTab + "&parent_category=" + cat_temp
          );
          setSymptoms(resultSymptoms.symptoms || []);
        } else {
          setCategories(result.symptoms || []);
        }
    }
    setLoading(false);
  };

  const setCategoryType = async (selectedCategory) => {
    let newCategories = categories.filter(
      (item) => item.id !== selectedCategory.id
    );
    setCategories([selectedCategory, ...newCategories]);
    if (category === selectedCategory.id) {
      setCategory("");
      setSymptoms([]);
      navigate(`/catalogue/${productsTab}`);
    } else {
      setCategory(selectedCategory.id);
      let result = await axiosFilter.getMedicineFilter(
        "type_of_medicine=" +
          productsTab +
          "&parent_category=" +
          selectedCategory.id
      );
      setSymptoms(result.symptoms || []);
      closeCategory();
      navigate(
        `/catalogue/${productsTab}?parent_category=${selectedCategory.id}`
      );
    }
    setSelectedSymptoms([]);
  };

  const setNewSelectedSymptoms = async (selectedSymptom) => {
    if (selectedSymptoms.find((item) => item === selectedSymptom)) {
      setSelectedSymptoms(
        selectedSymptoms.filter((item) => item !== selectedSymptom)
      );
      navigate(
        `/catalogue/${productsTab}?parent_category=${category}&symptoms=${selectedSymptoms.filter(
          (item) => item !== selectedSymptom
        )}`
      );
    } else {
      setSelectedSymptoms([selectedSymptom, ...selectedSymptoms]);
      navigate(
        `/catalogue/${productsTab}?parent_category=${category}&symptoms=${[
          selectedSymptom,
          ...selectedSymptoms,
        ]}`
      );
    }
  };

  const showCategory = () => {
    const categories = document.getElementById("groups_symptoms");
    const all = document.getElementById("all");
    const galochka = document.getElementById("galochka");
    categories.style.display = "block";
    all.style.color = "#20B900";
    galochka.style.stroke = "#20B900";
  };

  const closeCategory = () => {
    const categories = document.getElementById("groups_symptoms");
    const all = document.getElementById("all");
    const galochka = document.getElementById("galochka");
    if (all) {
      categories.style.display = "none";
      all.style.color = "#5F6B69";
      galochka.style.stroke = "#5F6B69";
    }
  };
  const listInnerRef = useOutsideClick(closeCategory);

  useEffect(() => {
    getDiseases();
    setProductsCount(0);

    switch (productsTab) {
      case "hits":
      case "new":
      case "promotions":
      case "search":
        setFilterWidth("40vw");
        break;
      default:
        setFilterWidth("30vw");
    }
  }, [productsTab, catalogue]);

  useEffect(() => {
    setSymptoms([]);
    setSelectedSymptoms(searchParams.get("symptoms")?.split(",") || []);
  }, [category]);

  useEffect(() => {
    setName(searchParams.get("name") || "");
    setCategories([]);
    setProductsCount(0);
  }, [searchParams.get("name")]);

  useEffect(() => {
    setPriceSorting(searchParams.get("order_by") || "");
    setCategoriesProducts(searchParams.get("categories") || "");
    setTypeOfMedicine(
      searchParams.get("type_of_medicine")?.split(",").filter(Boolean) || []
    );
    setProductsCount(0);
  }, [
    searchParams.get("categories"),
    searchParams.get("order_by"),
    searchParams.get("type_of_medicine"),
  ]);

  return (
    <section className={styles.container}>
      <Helmet>
        <title>
          {url} {name}
        </title>
        <link rel="canonical" href={`/catalogue/${productsTab}`} />
        <meta
          name="description"
          content={"Shalfey : Каталог товаров и услуг " + url + " " + name}
        />
        <meta
          name="keywords"
          content={`${url}, ${categories.map((item) => item.name).toString()}`}
        />
      </Helmet>
      {loading ? (
        <Loader bgColor="#20b900" align="center" />
      ) : (
        <div className={styles.content}>
          <div className={styles.menu}>
            <MainMenu typeOfMedicine={productsTab} />
          </div>
          <div>
            <HeaderCatalogue
              url={url}
              productsCount={productsCount}
              sortingProducts={sortingProducts}
              mainImage={mainImage}
              name={name}
              priceSorting={priceSorting}
              setIsModalOpen={setIsModalOpen}
            />
            <ModalWindow
              isOpen={isModalOpen}
              width={isMobile ? "100%" : filterWidth}
              onClose={() => setIsModalOpen(false)}
            >
              {isMobile ? (
                <MobileFilter onClose={() => setIsModalOpen(false)} />
              ) : (
                <Filter onClose={() => setIsModalOpen(false)} />
              )}
            </ModalWindow>

            {!!categories.length && (
              <div className={styles.diseases}>
                <div>
                  {Array.isArray(categories) &&
                    categories.slice(0, isMobile ? 1 : 3).map((item) => (
                      <div
                        key={item.id}
                        className={styles.disease}
                        onClick={() => setCategoryType(item)}
                      >
                        <img
                          className={styles.image}
                          src={
                            category === item.id
                              ? item.icon_url
                              : item.icon_gray_url || EmptyProduct
                          }
                          alt={item.name}
                        />
                        <p
                          className={
                            category === item.id ? styles.active : null
                          }
                        >
                          {item.name}
                        </p>
                      </div>
                    ))}
                </div>
                {!!categories.slice(isMobile ? 1 : 3).length && (
                  <div id="all" onClick={showCategory}>
                    ЕЩЁ&nbsp;&nbsp;
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="0.7vh"
                      viewBox="0 0 12 6"
                      fill="none"
                    >
                      <path
                        id="galochka"
                        d="M1.03223 1.09961C1.03223 1.09961 4.08549 3.61624 5.81683 4.93722C5.95479 5.04248 6.14592 5.03827 6.28141 4.92987C8.09967 3.47526 9.18762 2.6049 11.0692 1.09961"
                        stroke="#5F6B69"
                        strokeWidth="1.50555"
                        strokeLinecap="round"
                      />
                    </svg>
                  </div>
                )}
              </div>
            )}
            <div className={styles.symptoms}>
              {Array.isArray(symptoms) &&
                symptoms.map((item) => (
                  <div
                    key={item.id}
                    className={`${styles.symptom} ${
                      selectedSymptoms.find(
                        (elem) => elem === item.id.toString()
                      )
                        ? styles.activeSymptom
                        : null
                    }`}
                    onClick={() => setNewSelectedSymptoms(item.id.toString())}
                  >
                    {item.name}
                  </div>
                ))}
              <div
                ref={listInnerRef}
                id="groups_symptoms"
                className={styles.groups_symptoms}
              >
                {Array.isArray(categories) &&
                  categories.slice(isMobile ? 1 : 3).map((item) => (
                    <div key={item.id}>
                      <div
                        className={styles.disease}
                        onClick={() => setCategoryType(item)}
                      >
                        <img
                          className={styles.image}
                          src={item.icon_gray_url || EmptyProduct}
                          alt={item.name}
                        />
                        <p>{item.name}</p>
                      </div>
                      <div className={styles["horizontal-line"]} />
                    </div>
                  ))}
              </div>
            </div>
            <ProductList
              category={category}
              typeOfMedicine={typeOfMedicine}
              categoriesProducts={categoriesProducts}
              selectedSymptoms={selectedSymptoms}
              name={name}
              sortingProducts={sortingProducts}
              priceSorting={priceSorting}
              setProductsCount={setProductsCount}
            />
          </div>
        </div>
      )}
    </section>
  );
};

export { Catalogue };
