import React, { useEffect, useState } from "react";
import styles from "./Filter.module.scss";
import { Button } from "components";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { axiosHome, axiosFilter } from "plugins/axios";
import birdie from "assets/img/birdie.svg";

const Filter = ({ onClose, ...rest }) => {
  const navigate = useNavigate();
  const { catalogue, tab: productsTab } = useParams();
  const [medicineTab, setMedicineTab] = useState(false);
  const [searchParams] = useSearchParams();
  const [sorting, setSorting] = useState(1);

  const [typesOfMedicine, setTypesOfMedicine] = useState([]);
  const [herbalData, setHerbalData] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);

  const [price, setPrice] = useState("");
  const [medicine, setMedicine] = useState([]);
  const [categories, setCategories] = useState([]);

  const setPriceType = (value) => {
    if (price === value) {
      setPrice("");
    } else {
      setPrice(value);
    }
  };

  const setMedicineType = (value) => {
    let findItem = medicine.find((item) => item === value);
    if (findItem) {
      setMedicine(medicine.filter((item) => item !== value));
    } else {
      setMedicine([value, ...medicine]);
    }
  };

  const setCategoriesType = (value) => {
    let findItem = categories.find((item) => item === value);
    if (findItem) {
      setCategories(categories.filter((item) => item !== value));
    } else {
      setCategories([value, ...categories]);
    }
  };

  const setParams = () => {
    onClose();
    navigate(
      `/filter/${productsTab}?parent_category=${
        searchParams.get("parent_category") || ""
      }&symptoms=${
        searchParams.get("symptoms") || ""
      }&categories=${categories}&order_by=${price}&type_of_medicine=${medicine}`
    );
  };

  const setStartParams = () => {
    setPrice(0);
    setCategories([]);
    setMedicine([]);
  };

  const getFilterParams = async () => {
    let result = await axiosFilter.getFilterParams();
    setHerbalData(result.herbal_teas || []);
    setCategoriesData(result.drug_categories || []);
  };

  const getMainTypes = async () => {
    let result = await axiosHome.getMainTypes();
    setTypesOfMedicine(result || []);
  };

  useEffect(() => {
    getFilterParams();
    getMainTypes();
  }, []);

  useEffect(() => {
    setPrice(searchParams.get("order_by") || "");
    setCategories(searchParams.get("categories")?.split(",") || []);
    setMedicine(
      searchParams.get("type_of_medicine")?.split(",").filter(Boolean) || []
    );
  }, [catalogue]);

  useEffect(() => {
    switch (productsTab) {
      case "hits":
      case "new":
      case "promotions":
      case "search":
        setMedicineTab(true);
        break;
      default:
        setMedicineTab(false);
    }
  }, [productsTab]);

  return (
    <div className={styles.content} style={{ ...rest }}>
      <div className={styles.sorting}>
        <span
          className={sorting === 1 ? "selected" : ""}
          onClick={() => setSorting(1)}
        >
          По цене
        </span>
        {medicineTab && (
          <span
            className={sorting === 4 ? "selected" : ""}
            onClick={() => setSorting(4)}
          >
            Мир медицины
          </span>
        )}
        <span
          className={sorting === 2 ? "selected" : ""}
          onClick={() => setSorting(2)}
        >
          Сборы трав
        </span>
        <span
          className={sorting === 3 ? "selected" : ""}
          onClick={() => setSorting(3)}
        >
          Категории препаратов
        </span>
      </div>
      <div
        className={styles.variants}
        style={{ display: sorting === 1 ? "block" : "none" }}
      >
        <p
          onClick={() => setPriceType("asc")}
          style={{ color: price === "asc" ? "#20B900" : "#5f6b69" }}
        >
          По возрастанию
        </p>
        <div
          className={styles["horizontal-line"]}
          style={{
            borderBottomColor: price === "asc" ? "#20B900" : "#5f6b69",
          }}
        />
        <p
          onClick={() => setPriceType("desc")}
          style={{ color: price === "desc" ? "#20B900" : "#5f6b69" }}
        >
          По убыванию
        </p>
        <div
          className={styles["horizontal-line"]}
          style={{
            borderBottomColor: price === "desc" ? "#20B900" : "#5f6b69",
          }}
        />
      </div>
      <div
        className={styles.variants}
        style={{ display: sorting === 4 ? "block" : "none" }}
      >
        {typesOfMedicine.map((element) => (
          <div key={element.id}>
            <p
              onClick={() => setMedicineType(element.name)}
              style={{
                color: medicine.find((item) => item === element.name)
                  ? "#20B900"
                  : "#5f6b69",
              }}
            >
              {medicine.find((item) => item === element.name) && (
                <img src={birdie} alt="v" />
              )}
              {element.name}
            </p>
            <div
              className={styles["horizontal-line"]}
              style={{
                borderBottomColor: medicine.find(
                  (item) => item === element.name
                )
                  ? "#20B900"
                  : "#5f6b69",
              }}
            />
          </div>
        ))}
      </div>
      <div
        className={styles.variants}
        style={{ display: sorting === 2 ? "block" : "none" }}
      >
        {herbalData.map((element) => (
          <div key={element.id}>
            <p
              onClick={() => setCategoriesType(element.id.toString())}
              style={{
                color: categories.find((item) => item === element.id.toString())
                  ? "#20B900"
                  : "#5f6b69",
              }}
            >
              {categories.find((item) => item === element.id.toString()) && (
                <img src={birdie} alt="v" />
              )}
              {element.name}
            </p>
            <div
              className={styles["horizontal-line"]}
              style={{
                borderBottomColor: categories.find(
                  (item) => item === element.id.toString()
                )
                  ? "#20B900"
                  : "#5f6b69",
              }}
            />
          </div>
        ))}
      </div>
      <div
        className={styles.variants}
        style={{ display: sorting === 3 ? "block" : "none" }}
      >
        {categoriesData.map((element) => (
          <div key={element.id}>
            <p
              onClick={() => setCategoriesType(element.id.toString())}
              style={{
                color: categories.find((item) => item === element.id.toString())
                  ? "#20B900"
                  : "#5f6b69",
              }}
            >
              {categories.find((item) => item === element.id.toString()) && (
                <img src={birdie} alt="v" />
              )}
              {element.name}
            </p>
            <div
              className={styles["horizontal-line"]}
              style={{
                borderBottomColor: categories.find(
                  (item) => item === element.id.toString()
                )
                  ? "#20B900"
                  : "#5f6b69",
              }}
            />
          </div>
        ))}
      </div>
      <Button
        text="Готово"
        color="#20B900"
        bgColor="#F8FBF9"
        padding="1.6vh 2.5vh"
        margin="3vh 0 0"
        click={setParams}
      />
      <Button
        text="Очистить"
        color="#9CA5A3"
        bgColor="#fff"
        padding="1.6vh 2.5vh"
        click={setStartParams}
      />
    </div>
  );
};

export { Filter };
