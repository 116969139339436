import styles from "./Product.module.scss";
import React, { useEffect, useState } from "react";
import { Reviews, ProductInfo, MainMenu } from "components";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { axiosProduct } from "plugins/axios";
import { SliderProducts } from "components";
import slide_right from "assets/img/slide_right.svg";

const Product = () => {
  const [product, setProduct] = useState({});
  const [recommendations, setRecommendations] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [reviewsCount, setReviewsCount] = useState([]);
  const params = useParams();
  const navigate = useNavigate();

  const getProduct = async () => {
    let { info } = await axiosProduct.getProduct(params.id);
    if (info) {
      setProduct(info || {});
      if (info?.id) {
        let resultRecommendations = await axiosProduct.getRecommendations(
          info.id
        );
        setRecommendations(resultRecommendations.similar || []);
        let resultProductReviews = await axiosProduct.getProductReviews(
          info.id
        );
        setReviewsCount(resultProductReviews.count || 0);
        setReviews(resultProductReviews.results || []);
      }
    } else {
      navigate("*");
    }
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
  };
  useEffect(() => {
    getProduct();

    let history = localStorage.getItem("history")
      ? JSON.parse(localStorage.getItem("history"))
      : [];
    if (!history.find((e) => e == params.id)) {
      history = [params.id, ...history];
    }
    localStorage.setItem("history", JSON.stringify(history.slice(0, 4)));
    window.scrollTo(0, 0);
  }, [params.id]);

  return (
    <div className={styles.container}>
      <div className={styles.menu}>
        <MainMenu />
      </div>
      <Helmet>
        <title>{product?.seo_title || product?.name || "Товар"}</title>

        <meta
          name="description"
          content={
            product?.seo_desc ||
            "Shalfey : " +
              product.name +
              ". " +
              String(product.description).substring(0, 50)
          }
        />
        <link rel="canonical" href={`/product/${params.id}`} />
        <meta name="keywords" content={product?.seo_keywords} />
      </Helmet>
      <div className={styles.section}>
        <h3 className={styles.heading}>
          Препараты
          <img src={slide_right} alt="Стрелка" style={{ margin: "0 10px" }} />
          {product.name}
        </h3>
        <ProductInfo product={product} />
        {!!recommendations.length && (
          <div className={styles.recomended}>
            <div className={styles.recomended_text}>Похожие товары</div>
            <div>
              {<SliderProducts items={recommendations} settings={settings} />}
            </div>
          </div>
        )}
        <Reviews
          reviews={reviews}
          reviewsCount={reviewsCount}
          average_mark={product.product_mark}
          productId={product.id}
        />
      </div>
    </div>
  );
};

export { Product };
