import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import styles from "./Statics.module.scss";
import about_us_fammily from "assets/img/about_us_family.png";
import about_us_background from "assets/img/about_us_background.png";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className={styles.about}>
      <Helmet title="О нас">
        <meta name="description" content="Shalfey : О нас" />
        <meta
          name="keywords"
          content="Экосистема, Шалфей, уникальное онлайн-пространство"
        />
        <link rel="canonical" href="/about" />
      </Helmet>
      <div className={styles.about__header}>
        <div className={styles.about__header__title}>
          <h1>Экосистема Шалфей</h1>
          <h2>уникальное онлайн-пространство</h2>
        </div>
        <img
          src={about_us_fammily}
          className={styles.about__header__image}
          alt="about us"
        />
      </div>
      <div className={styles.about__blocks}>
        <div className={styles.about__block}>
          <h3 className={styles.about__block__title}>О нас</h3>
          <div className={styles.about__block__tasks}>
            <div className={styles.about__block__task}>
              <p className={styles.about__block__task__description}>
                В каждой семье, в каждом роду хранятся секреты здоровья,
                передаваемые из поколения в поколение. 
              </p>
              <p className={styles.about__block__task__description}>
                Это ценные знания, впитавшие в себя мудрость предков, силу
                традиций и гармонию с природой.
              </p>
            </div>
            <div className={styles.about__block__task}>
              <p className={styles.about__block__task__description}>
                Экосистема «Шалфей» бережно собирает эти секреты и объединяет их
                с современными исследованиями.
              </p>
              <p className={styles.about__block__task__description}>
                Мы верим в силу природы и хотим, чтобы вы могли пользоваться ею
                с уверенностью.
              </p>
            </div>
          </div>
        </div>
        <div className={styles.about__block}>
          <h3 className={styles.about__block__title}>Наша цель</h3>
          <div className={styles.about__block__tasks}>
            <h4 className={styles.about__block__tasks__title}>
              Мы поставили перед собой основные задачи:
            </h4>
            <div className={styles.about__block__task}>
              <p className={styles.about__block__task__title}>
                1. Облегчить доступ к качественным натуральным и природным
                препаратам.
              </p>
              <p className={styles.about__block__task__description}>
                Анкета, созданная практикующими экспертами с медицинским
                образованием, поможет найти именно то, что вам нужно,
                классифицируя препараты по их воздействию на организм.
              </p>
            </div>
            <div className={styles.about__block__task}>
              <p className={styles.about__block__task__title}>
                2. Соединить традиции и науку.
              </p>
              <p className={styles.about__block__task__description}>
                Мы изучаем и проверяем эффективность традиционных методов
                лечения, чтобы предлагать вам надёжные и проверенные решения.
              </p>
            </div>
            <div className={styles.about__block__task}>
              <p className={styles.about__block__task__title}>
                3. Создать сообщество осознанных людей.
              </p>
              <p className={styles.about__block__task__description}>
                ШалфейВики поможет вам узнать больше о здоровье, питании,
                экологичном образе жизни и найти единомышленников.
              </p>
            </div>
          </div>
        </div>
      </div>
      <img
        src={about_us_background}
        alt=""
        className={styles.about__background_image}
      />
    </div>
  );
};

export { About };
